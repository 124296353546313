import React from 'react';
import { connect } from 'react-redux';
import { Paper, StepButton, Stepper, Step, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPadding: {
      paddingRight: '0!important',
      paddingLeft: '0!important',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    formWrapper: {
      padding: 50,
      minHeight: 555,
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      marginBottom: 15,
    },
    stepButtonRoot: {
      margin: '-24px -24px',
      padding: '24px 24px',

      [theme.breakpoints.down('sm')]: {
        padding: 0,
        margin: 0,
      },
    },
  })
);

const StepProgress: React.FunctionComponent<StepProgressProps> = (props) => {
  const classes = useStyles();

  const { steps, activeStep, isAddonsDisabled, isSubmit, isSubmitError, isSubmitting } = props;

  return (
    <Paper className={classes.paper}>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton
              disableRipple
              completed={activeStep >= index + 1 || isSubmit}
              className={classes.stepButtonRoot}
              disabled={
                isAddonsDisabled && index === 1
                  ? true
                  : false || isSubmit || isSubmitError || isSubmitting
              } // Disable the addons step if set or if the form has been submit
              onClick={(event) => event.preventDefault()}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    isSubmit: state.brand.isSubmit,
    isSubmitting: state.brand.isSubmitting,
    isSubmitError: state.brand.isSubmitError,
  };
};

interface StepProgressProps {
  steps: string[];
  activeStep: number;
  handleStep: Function;
  isAddonsDisabled: boolean;
  isSubmit: boolean;
  isSubmitting: boolean;
  isSubmitError: boolean;
}

export default connect(mapStateToProps)(StepProgress);

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Typography, Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import { lighten } from 'polished';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import currency from 'currency.js';

// Actions
import { handleBestDeal } from '../../actions/brand';

// Utils
import { calculatePriceMonthly } from '../../utils/utils';

// Vars
import { styleConfig } from '../../../config/config';

// Type defs
import {
  WordpressWpCarConnection,
  WordpressWpBrand,
  WordpressWpBrandAcfBestDeals,
  WordpressWpCar,
} from '../../defs/generated-models';

const RecommendedDeals: React.FunctionComponent<RecommendedDealsProps> = (props) => {
  const {
    agreementTerms,
    annualMileage,
    bestDeals,
    brand,
    handleBestDeal,
    initialRental,
    initialVariants,
    isLoading,
    leaseType,
    selectedVariant,
  } = props;

  // Styles
  const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
      backgroundColor: styleConfig.color.bg.offwhite,
      border: '1px #DDD solid',
      padding: '100px 0',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },

      [theme.breakpoints.down('sm')]: {
        padding: '40px 0',
      },
    },
    title: {
      fontSize: 38,
      fontWeight: 600,
      marginBottom: 25,

      [theme.breakpoints.down('sm')]: {
        fontSize: 30,
      },
    },
    table: {
      margin: '0 0 30px 0',
      width: '100%',
      borderCollapse: 'collapse',
    },
    tableRow: {
      cursor: 'pointer',
      backgroundColor: '#EEEEEE',
      color: '#2b2b2b',

      '& > td': {
        transition: '0.1s ease-in-out all',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        height: 50,
        width: 75,
      },

      '&:hover': {
        cursor: 'pointer',

        '& > td': {
          borderRight: '1px #444 solid',
          transition: '0.1s ease-in-out all',
          background: `${lighten(0.05, styleConfig.color.bg.darkgrey)}`,

          '&:nth-child(2)': {
            background:
              leaseType === 'business'
                ? styleConfig.color.bg.darkgrey
                : lighten(0.05, styleConfig.color.bg.darkgrey),
          },

          '&:nth-child(3)': {
            background:
              leaseType === 'personal'
                ? styleConfig.color.bg.darkgrey
                : lighten(0.05, styleConfig.color.bg.darkgrey),
          },

          '& > p': {
            color: '#FFF',
          },
        },
      },
    },
    tableRowActive: {
      cursor: 'pointer',

      '& > td': {
        borderRight: '1px #444 solid',

        '&:nth-child(2)': {
          background:
            leaseType === 'business'
              ? styleConfig.color.bg.darkgrey
              : lighten(0.05, styleConfig.color.bg.darkgrey),
        },

        '&:nth-child(3)': {
          background:
            leaseType === 'personal'
              ? styleConfig.color.bg.darkgrey
              : lighten(0.05, styleConfig.color.bg.darkgrey),
        },

        '&:nth-child(2), &:nth-child(3)': {
          '& p': {
            fontWeight: 600,
            color: styleConfig.color.text.gold,
          },
        },
      },

      '&:hover': {
        cursor: 'pointer',

        '& > td': {
          background: lighten(0.05, styleConfig.color.bg.darkgrey),
        },
      },
    },
    tableHeader: {
      backgroundColor: '#FFF',

      '& > td': {
        '&  > p': {
          fontSize: 17,
        },
        '&:nth-child(1)': {
          '& p': {
            fontSize: 19,

            [theme.breakpoints.down('sm')]: {},
          },
        },
      },

      '& p': {
        fontWeight: 600,

        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
    },
    tableHeaderData: {
      fontWeight: 600,
      padding: 30,

      [theme.breakpoints.down('sm')]: {
        padding: 5,

        '&:first-child': {
          width: '50%',
        },

        width: '25%',
      },
    },
    tableData: {
      width: '17.5%',
      padding: 20,
      borderRight: '1px #ddd solid',

      '& p': {
        fontWeight: 600,
      },

      [theme.breakpoints.down('md')]: {
        width: '25%',

        '& p': {
          fontSize: 17,
        },
      },

      [theme.breakpoints.down('sm')]: {
        width: '25%',
        padding: 5,
        '& p': {
          textAlign: 'center',
          fontSize: 13,
        },
      },

      '&:first-child': {
        width: '65%',

        [theme.breakpoints.down('md')]: {
          width: '50%',
        },

        [theme.breakpoints.down('sm')]: {
          width: '50%',

          '& p': {
            fontSize: 19,
          },
        },

        [theme.breakpoints.down('xs')]: {
          '& p': {
            fontSize: 13,
          },
        },

        maxWidth: '100%',
      },
    },
    tableDataVariant: {
      '&:first-child': {
        paddingLeft: 30,

        [theme.breakpoints.down('sm')]: {
          paddingLeft: 5,
        },
      },
      '& > p': {
        position: 'relative',
      },
    },
    checkedIconData: {
      verticalAlign: 'middle',
    },
    tableHeaderDataLeaseType: {
      display: 'flex',
      '& > svg': {
        marginRight: 10,

        [theme.breakpoints.down('sm')]: {
          display: 'none',
          marginRight: 0,
        },
      },
    },
    tableHeaderBusiness: {
      borderLeft: '1px #EAEAEA solid',
      borderRight: '1px #EAEAEA solid',
      '& > p': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    tableHeaderPersonal: {
      '&:last-child': {
        paddingRight: '20px!important',
      },
      '& > p': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    loading: {
      opacity: 0.6,
      pointerEvents: 'none',
    },
    tableDataBusiness: {
      backgroundColor: '#EAEAEA',
      color: `${styleConfig.color.text.gold}!important`,
      '& > p': {
        fontSize: 22,
        textAlign: 'center',
      },
    },
    tableDataPersonal: {
      backgroundColor: '#EEEEEE',
      '& > p': {
        fontSize: 22,
        textAlign: 'center',
      },
    },
    tableDataActive: {
      background: lighten(0.05, styleConfig.color.bg.darkgrey),
      color: '#FFF',
      borderLeft: 'none',

      '&:hover': {
        cursor: 'pointer',
        background: '#2B2B2B!important',
      },

      '& p': {
        fontWeight: 600,
        color: '#FFF',
      },
    },
    pricesLabel: {
      color: styleConfig.color.text.gray,
    },

    tableRoot: {
      [theme.breakpoints.down('sm')]: {
        overflowX: 'scroll',
      },
    },
  }));

  const classes = useStyles();

  function handleSelect(variantId) {
    // Disable the function if the variant is changing or is already selected

    const element = document.getElementById('titleHeader');

    if (variantId !== selectedVariant.wordpress_id) {
      element && element.scrollIntoView({ block: 'start' });

      return handleBestDeal(variantId, initialVariants);
    }

    return true;
  }

  return !isEmpty(bestDeals) ? (
    <Box component="section" className={classes.wrapper} id="recommendedDeals">
      <Container maxWidth="xl">
        <Typography variant="h3" component="div" className={classes.title}>
          Recommended Deals
        </Typography>

        <div className={clsx('overflow-table', classes.tableRoot)}>
          <table className={clsx(classes.table, isLoading && classes.loading)}>
            <thead>
              <tr className={classes.tableHeader}>
                <td className={classes.tableHeaderData}>
                  <Typography color="primary">
                    {brand.parent_element && brand.parent_element.name} {brand.name}
                  </Typography>
                </td>
                <td className={clsx([classes.tableHeaderData, classes.tableHeaderBusiness])}>
                  <Typography className={classes.tableHeaderDataLeaseType}>
                    {leaseType === 'business' && <CheckIcon color="primary" />}
                    Business Leasing
                  </Typography>
                </td>
                <td className={clsx([classes.tableHeaderData, classes.tableHeaderPersonal])}>
                  <Typography className={classes.tableHeaderDataLeaseType}>
                    {leaseType === 'personal' && <CheckIcon color="primary" />}
                    Personal Leasing
                  </Typography>
                </td>
              </tr>
            </thead>

            <tbody>
              {bestDeals.map((deal, index) => {
                // if the deal is the currently selected variant
                const isActive = selectedVariant.wordpress_id === deal.wordpress_id;

                const businessPrice = calculatePriceMonthly(
                  'business',
                  agreementTerms,
                  initialRental,
                  annualMileage,
                  deal
                );

                const personalPrice = calculatePriceMonthly(
                  'personal',
                  agreementTerms,
                  initialRental,
                  annualMileage,
                  deal
                );

                return (
                  <tr
                    className={clsx([classes.tableRow, isActive && classes.tableRowActive])}
                    key={`recommended-deals-row-${index}`}
                    onClick={() => handleSelect(deal.wordpress_id)}
                  >
                    <td
                      className={clsx(
                        classes.tableData,
                        classes.tableDataVariant,
                        isActive && classes.tableDataActive
                      )}
                    >
                      <Typography>
                        {isActive && (
                          <CheckIcon className={classes.checkedIconData} color="primary" />
                        )}{' '}
                        {deal.post_meta_fields && deal.post_meta_fields.title}
                      </Typography>
                    </td>
                    <td
                      className={clsx([
                        classes.tableData,
                        classes.tableDataBusiness,
                        isActive && classes.tableDataActive,
                      ])}
                    >
                      <Typography>
                        {businessPrice === 0 || businessPrice === 'POA'
                          ? '£POA'
                          : `${currency(businessPrice, { precision: 0, symbol: '£' }).format()}`}
                      </Typography>
                    </td>
                    <td
                      className={clsx([
                        classes.tableData,
                        classes.tableDataPersonal,
                        isActive && classes.tableDataActive,
                      ])}
                    >
                      <Typography>
                        {personalPrice === 0 || businessPrice === 'POA'
                          ? '£POA' || businessPrice === 'POA'
                          : `${currency(personalPrice, { precision: 0, symbol: '£' }).format()}`}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Container>
    </Box>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    agreementTerms: state.brand.agreementTerms,
    annualMileage: state.brand.annualMileage,
    bestDeals: state.brand.bestDeals,
    brand: state.brand.brand,
    initialRental: state.brand.initialRental,
    initialVariants: state.brand.initialVariants,
    isLoading: state.brand.isLoadingVariant,
    leaseType: state.brand.leaseType,
    selectedVariant: state.brand.selectedVariant,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({ handleBestDeal }, dispatch);

interface RecommendedDealsProps {
  agreementTerms: number;
  annualMileage: number;
  bestDeals: WordpressWpBrandAcfBestDeals[];
  brand: WordpressWpBrand;
  handleBestDeal: Function;
  initialRental: number;
  initialVariants: WordpressWpCarConnection;
  isLoading: boolean;
  leaseType: string;
  selectedVariant: WordpressWpCar;
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedDeals);

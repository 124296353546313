import React from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Label from '../../../Common/Label';
import Title from './Title';
import { WordpressWpBrand, WordpressWpCar } from '../../../../defs/generated-models';
import { styleConfig } from '../../../../../config/config';
import clsx from 'clsx';
import { smoothScrollToElement } from '../../../../utils/utils';
import TextButton from '../../../TextButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#2B2B2B',
    padding: '35px 0',
    position: 'relative',

    '& h1': {
      [theme.breakpoints.down('md')]: {
        marginBottom: 30,
        fontSize: 45,
        textAlign: 'center',
      },
    },
  },
  description: {
    fontSize: 16,
    color: '#FFF',
    lineHeight: '28px',
    marginBottom: 15,
    width: '90%',
  },
  descriptionBrand: {
    position: 'relative',

    '& > span:after': {
      display: 'inline-block',
      content: `'..'`,
      marginRight: 10,
    },
  },
  prices: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  labelRoot: {
    bottom: -10,
    position: 'absolute',

    [theme.breakpoints.down('sm')]: {
      position: 'static',
      marginTop: 30,
    },
  },
}));

const TitleHeader: React.FunctionComponent<TitleHeaderProps> = (props) => {
  const { brand, description, title, selectedVariant } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root} id="titleHeader">
      <Container maxWidth="xl">
        <Grid container spacing={5} justifyContent="space-between">
          <Grid item xl={6} lg={5} sm={12} md={12}>
            <Title title={title} />
          </Grid>

          <Grid item xl={6} lg={7} md={12} sm={12} xs={12}>
            {description && <p className={classes.description}>{description}</p>}

            {!description &&
              brand &&
              brand.acf &&
              brand.acf.content_modules_undefined &&
              brand.acf.content_modules_undefined[0] &&
              brand.acf.content_modules_undefined[0].__typename ===
                'WordPressAcf_image_with_content_multiple' && (
                <div>
                  <p
                    id="variant-short-desc"
                    className={clsx([classes.description, classes.descriptionBrand])}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          (
                            brand.acf.content_modules_undefined[0] &&
                            brand.acf.content_modules_undefined[0].submodules &&
                            brand.acf.content_modules_undefined[0].submodules[0] &&
                            brand.acf.content_modules_undefined[0].submodules[0].content?.split(
                              '</p>'
                            )[0]
                          )?.replace('<p>', '') || '',
                      }}
                    />

                    <TextButton
                      color={styleConfig.color.text.gold}
                      onClick={() => smoothScrollToElement('module-1')}
                    >
                      Read more
                    </TextButton>
                  </p>
                </div>
              )}
          </Grid>
        </Grid>

        {selectedVariant && selectedVariant.acf && selectedVariant.acf.special_offer && (
          <Label className={classes.labelRoot}>
            {selectedVariant.acf.special_offer_info_bar || 'Special Offer'}
          </Label>
        )}
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    brand: state.brand.brand,
    isError: state.brand.isError,
    isLoading: state.brand.isLoading,
    isSubmit: state.brand.isSubmit,
    isSubmitError: state.brand.isSubmitError,
    isSubmitting: state.brand.isSubmitting,
    selectedVariant: state.brand.selectedVariant,
  };
};

interface TitleHeaderProps {
  brand: WordpressWpBrand;
  description?: string | null | undefined;
  isSubmit: boolean;
  isError: boolean;
  isLoading: boolean;
  isSubmitError: boolean;
  isSubmitting: boolean;
  selectedVariant: WordpressWpCar;
  title: string;
}

export default connect(mapStateToProps)(TitleHeader);

import React from 'react';
import { connect } from 'react-redux';
import { Typography, Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isNull from 'lodash/isNull';

// Type defs
import { WordpressWpCar } from '../../defs/generated-models';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 20,

    [theme.breakpoints.down('lg')]: {
      marginTop: 15,
      display: 'flex',
    },

    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  },
  item: {
    borderRight: '1px #ddd solid',
    paddingRight: 20,
    marginLeft: 20,
    '&:first-of-type': {
      marginLeft: 0,
    },

    [theme.breakpoints.down('lg')]: {
      paddingRight: 10,
      marginLeft: 5,
      marginBottom: 10,

      '& $label': {
        fontSize: 12,
      },
    },

    [theme.breakpoints.down('md')]: {
      paddingRight: 5,
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0 0 10px 0',
      margin: '0 0 10px',
      display: 'flex',
      flex: '0 0 50%',
      borderRight: 0,
    },
  },
  spec: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontFamily: 'Bai Jamjuree',
    fontWeight: 600,
    lineHeight: 1,

    [theme.breakpoints.down('lg')]: {
      fontSize: 17,
    },

    [theme.breakpoints.down('sm')]: {
      marginRight: 7,
    },
  },
  label: {
    fontSize: 14,
    color: theme.palette.primary.dark,
  },
}));

const Specs: React.FunctionComponent<SpecsProps> = (props) => {
  const { selectedVariant } = props;

  const classes = useStyles();

  const isElectric =
    selectedVariant &&
    selectedVariant.post_meta_fields &&
    selectedVariant.post_meta_fields.fuel === 'Electric';

  const isZeroNonElectricCO2 =
    !isElectric &&
    selectedVariant &&
    selectedVariant.post_meta_fields &&
    selectedVariant.post_meta_fields.specs &&
    selectedVariant.post_meta_fields.specs.spec_CO2 === 0;

  const isZeroNonElectricMPG =
    !isElectric &&
    selectedVariant &&
    selectedVariant.post_meta_fields &&
    selectedVariant.post_meta_fields.specs &&
    selectedVariant.post_meta_fields.specs.spec_MPG3 === 0;

  const noSpecs =
    selectedVariant &&
    selectedVariant.post_meta_fields &&
    selectedVariant.post_meta_fields.specs &&
    selectedVariant.post_meta_fields.specs.spec_BHP === 0 &&
    selectedVariant.post_meta_fields.specs.spec_0_62 === 0 &&
    selectedVariant.post_meta_fields.specs.spec_MPG3 === 0 &&
    selectedVariant.post_meta_fields.specs.spec_CO2 === 0;

  return (
    <React.Fragment>
      {selectedVariant.post_meta_fields && selectedVariant.post_meta_fields.specs && (
        <Box className={classes.wrapper}>
          {selectedVariant.post_meta_fields.specs.spec_BHP ? (
            <Box className={classes.item}>
              <Typography className={classes.spec}>
                {noSpecs && !isNull(selectedVariant.post_meta_fields.specs.spec_BHP)
                  ? 'n/a'
                  : selectedVariant.post_meta_fields.specs.spec_BHP}{' '}
                BHP
              </Typography>
              <Typography className={classes.label}>Power</Typography>
            </Box>
          ) : null}

          {selectedVariant.post_meta_fields.specs.spec_0_62 ? (
            <Box className={classes.item}>
              <Typography className={classes.spec}>
                {noSpecs && !isNull(selectedVariant.post_meta_fields.specs.spec_0_62)
                  ? 'n/a'
                  : selectedVariant.post_meta_fields.specs.spec_0_62}{' '}
                secs
              </Typography>
              <Typography className={classes.label}>0-62mph</Typography>
            </Box>
          ) : null}

          <Box className={classes.item}>
            <Typography className={classes.spec}>
              {(noSpecs && !isNull(selectedVariant.post_meta_fields.specs.spec_MPG3)) ||
              isZeroNonElectricMPG
                ? 'n/a'
                : selectedVariant.post_meta_fields.specs.spec_MPG3}{' '}
              mpg
            </Typography>
            <Typography className={classes.label}>Efficiency</Typography>
          </Box>

          <div className={classes.item}>
            <Typography className={classes.spec}>
              {(noSpecs && !isNull(selectedVariant.post_meta_fields.specs.spec_CO2)) ||
              isZeroNonElectricCO2
                ? 'n/a'
                : selectedVariant.post_meta_fields.specs.spec_CO2}{' '}
              g/km
            </Typography>
            <Typography className={classes.label}>Emissions</Typography>
          </div>
        </Box>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedVariant: state.brand.selectedVariant,
    isLoading: state.brand.isLoadingVariant,
  };
};

interface SpecsProps {
  selectedVariant: WordpressWpCar;
  isLoading: boolean;
}

export default connect(mapStateToProps)(Specs);

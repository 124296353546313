import React from 'react';
import { connect } from 'react-redux';
import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styleConfig } from '../../../../config/config';
import Steps from './Steps/Steps';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: styleConfig.color.bg.offwhite,
  },
  preTitle: {
    color: styleConfig.color.text.gold,
    fontSize: 20,
    margin: 0,
  },
  content: {
    padding: '50px 0',
  },
  title: {
    color: '#2B2B2B',
    fontSize: 50,
    fontWeight: 600,
    fontFamily: 'Bai Jamjuree',
    lineHeight: 1,
    marginBottom: 50,

    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
}));

const CustomiseLease: React.FunctionComponent<CustomiseLeaseProps> = (props) => {
  const { isSubmit } = props;
  const classes = useStyles();

  return (
    <Box className={classes.wrapper} id="customiseLease">
      <div className={classes.content}>
        {!isSubmit && (
          <Box textAlign="center">
            <Typography component="p" className={classes.preTitle}>
              Almost there...
            </Typography>
            <Typography component="h3" className={classes.title}>
              Customise your lease
            </Typography>
          </Box>
        )}

        <Steps />
      </div>
    </Box>
  );
};

interface CustomiseLeaseProps {
  isSubmit: boolean;
}

const mapStateToProps = (state) => {
  return {
    isSubmit: state.brand.isSubmit,
  };
};

export default connect(mapStateToProps)(CustomiseLease);

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Typography, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import GroupedButton from '../GroupedButton';

// Constants
import {
  initialRentalMarks,
  agreementTermsMarks,
  annualMileageMarks,
  tooltipValues,
  leaseTypeButtons,
} from '../../constants';

// Actions
import {
  handleFilterVariants,
  handleUpdateAgreementTerms,
  handleUpdateAnnualMileage,
  handleUpdateInitialRental,
  handleUpdateVariant,
  handleUpdateAddons,
  handleUpdateLeaseType,
} from '../../actions/brand';

// Functions
import { onlyUnique, smoothScrollToElement } from '../../utils/utils';

// Components
import InputRange from '../InputRange';
import InputSelect from '../InputSelect';
import InputSelectVariant from '../InputSelectVariant';
import MuiButton from '../MuiButton';
import PipePrimary from '../Common/PipeButton';

// Types
import {
  WordpressWpCarConnection,
  WordpressWpCar,
  WordpressWpBrandAcf,
} from '../../defs/generated-models';
import clsx from 'clsx';

// Styles
const useStyles = makeStyles((theme) => ({
  noPadding: {
    paddingLeft: '0!important',
    paddingRight: '0!important',
  },
  title: {
    color: theme.palette.primary.main,
    display: 'block',
    fontFamily: 'Open Sans',
    fontSize: 20,
    fontWeight: 600,
    width: '100%',
    marginBottom: 40,
  },
  inputGroup: {
    marginBottom: 20,
  },
  inputsRoot: {
    [theme.breakpoints.down('md')]: {
      padding: '0 12px 0 0',
    },
  },

  options: {
    padding: '0 15px',
  },

  dealBtnRoot: {
    display: 'flex',
    position: 'relative',

    '& button': {
      marginRight: 15,
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',

      '& > button': {
        marginRight: 0,
        width: '100%',
        marginBottom: 15,
      },
    },
  },
}));

const LeaseOptions: React.FunctionComponent<LeaseOptionsProps> = (props) => {
  const {
    agreementTerms,
    annualMileage,
    bestDeals,
    fuelType,
    fuelTypes,
    groupOptions,
    handleFilterVariants,
    handleUpdateAddons,
    handleUpdateLeaseType,
    handleUpdateAgreementTerms,
    handleUpdateAnnualMileage,
    handleUpdateInitialRental,
    handleUpdateVariant,
    initialRental,
    initialVariants,
    isLoading,
    leaseType,
    selectedAddons,
    selectedVariant,
    trimType,
    variants,
  } = props;

  // Get all the trims
  const allTrims =
    (initialVariants &&
      initialVariants.edges &&
      initialVariants.edges.length > 0 &&
      initialVariants.edges.map(
        (variant) =>
          variant.node.post_meta_fields &&
          variant.node.post_meta_fields.trim &&
          variant.node.post_meta_fields.trim
      )) ||
    [];

  // Filter the duplicate trims to get an array of available trims
  const uniqueTrims = allTrims.filter(onlyUnique);

  function handleTrimType(val) {
    if (val !== trimType) {
      return handleFilterVariants(val, fuelType, initialVariants);
    }

    return;
  }

  function handleFuelType(val) {
    if (val !== fuelType) {
      return handleFilterVariants(trimType, val, initialVariants);
    }

    return;
  }

  function handleVariant(val) {
    // Filter the array of variants to get the selected variant object
    const selectedVariant = variants.edges.filter((variant) => variant.node.wordpress_id === val);

    handleUpdateVariant(selectedVariant[0].node);
  }

  function handleRental(val) {
    if (val !== initialRental) {
      if (!isEmpty(selectedAddons)) {
        handleUpdateAddons(selectedAddons, leaseType, agreementTerms, val, groupOptions);
      }

      return handleUpdateInitialRental(val);
    }

    return;
  }

  function handleTerms(val) {
    if (val !== agreementTerms) {
      if (!isEmpty(selectedAddons)) {
        handleUpdateAddons(selectedAddons, leaseType, agreementTerms, val, groupOptions);
      }

      return handleUpdateAgreementTerms(val);
    }

    return true;
  }

  function handleMileage(val) {
    if (val !== annualMileage) {
      return handleUpdateAnnualMileage(val);
    }

    return;
  }

  function handleLeaseType(val) {
    if (val !== leaseType) {
      return handleUpdateLeaseType(val);
    }
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <Box component="div" className={classes.inputGroup}>
        <GroupedButton
          handleClick={handleLeaseType}
          items={leaseTypeButtons}
          selected={leaseType}
          marginBottom={30}
        />

        <Typography variant="h4" component="div" className={classes.title}>
          Choose your car
        </Typography>

        <Grid container spacing={3}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            {uniqueTrims && (
              <InputSelect
                disabled={isLoading}
                fullWidth
                handleChange={handleTrimType}
                isTooltip
                label
                labelText="Trim"
                marginBottom={25}
                menuItems={uniqueTrims}
                name="trimType"
                tooltipContent={tooltipValues.trimType}
                value={trimType}
              />
            )}
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputSelect
              disabled={isLoading}
              fullWidth
              handleChange={handleFuelType}
              isTooltip
              label
              labelText="Fuel Type"
              marginBottom={25}
              menuItems={fuelTypes}
              name="fuelType"
              tooltipContent={tooltipValues.fuelType}
              value={fuelType}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {variants.edges && (
              <InputSelectVariant
                disabled={isLoading}
                fullWidth
                handleChange={handleVariant}
                isTooltip
                label
                labelText="Variant"
                menuItems={variants.edges}
                name="variant"
                value={
                  (selectedVariant &&
                    selectedVariant.wordpress_id &&
                    selectedVariant.wordpress_id.toString()) ||
                  'Unknown'
                }
                tooltipContent={tooltipValues.variant}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      <Box component="div" className={clsx([classes.inputsRoot, classes.inputGroup])}>
        <Typography variant="h4" component="div" className={classes.title}>
          Set your lease preferences
        </Typography>

        <InputRange
          handleChange={handleRental}
          isTooltip
          labelText="Initial Rental (Months)"
          marks={initialRentalMarks}
          max={12}
          min={3}
          name="initialRental"
          step={3}
          tooltipContent={tooltipValues.initialRental}
          value={initialRental}
        />

        <InputRange
          handleChange={handleTerms}
          isTooltip
          labelText="Contract Hire Term (Months)"
          marks={agreementTermsMarks}
          max={48}
          min={24}
          name="agreementTerms"
          step={12}
          tooltipContent={tooltipValues.agreementTerms}
          value={agreementTerms}
        />

        <InputRange
          handleChange={handleMileage}
          isTooltip
          labelText="Annual Mileage"
          marks={annualMileageMarks}
          max={20000}
          min={8000}
          name="annualMileage"
          step={1000}
          tooltipContent={tooltipValues.annualMileage}
          value={annualMileage}
        />
      </Box>

      <Box className={classes.dealBtnRoot}>
        {!isEmpty(bestDeals) && (
          <MuiButton
            aria-label="See our Recommended Deals"
            height={61}
            onClick={() => smoothScrollToElement('recommendedDeals')}
            name="recommended-deals"
            variant="outlined"
          >
            See Our Recommended Deals
            <PipePrimary />
          </MuiButton>
        )}

        <MuiButton
          aria-label="Enquire now"
          height={61}
          onClick={() => smoothScrollToElement('customiseLease')}
          name="enquire-now"
          variant="contained"
        >
          Enquire now
        </MuiButton>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    agreementTerms: state.brand.agreementTerms,
    annualMileage: state.brand.annualMileage,
    bestDeals: state.brand.bestDeals,
    fuelType: state.brand.fuelType,
    fuelTypes: state.brand.fuelTypes,
    groupOptions: state.brand.groupOptions,
    initialRental: state.brand.initialRental,
    initialVariants: state.brand.initialVariants,
    isLoading: state.brand.isLoadingVariant,
    leaseType: state.brand.leaseType,
    selectedAddons: state.brand.selectedAddons,
    selectedVariant: state.brand.selectedVariant,
    trimType: state.brand.trim,
    variants: state.brand.variants,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleFilterVariants,
      handleUpdateVariant,
      handleUpdateAgreementTerms,
      handleUpdateInitialRental,
      handleUpdateAnnualMileage,
      handleUpdateAddons,
      handleUpdateLeaseType,
    },
    dispatch
  );

interface LeaseOptionsProps {
  annualMileage: number;
  agreementTerms: number;
  bestDeals: WordpressWpBrandAcf[];
  fuelType: string;
  fuelTypes: ['business' | 'personal'];
  groupOptions: {
    label: string;
    options: {
      isPOA: boolean;
      label: string;
      parent: string;
      priceExcVat: number;
      priceIncVat: number;
      value: string;
    }[];
  }[];
  handleFilterVariants: Function;
  handleUpdateAddons: Function;
  handleUpdateAgreementTerms: Function;
  handleUpdateLeaseType: Function;
  handleUpdateAnnualMileage: Function;
  handleUpdateInitialRental: Function;
  handleUpdateVariant: Function;
  initialRental: number;
  initialVariants: WordpressWpCarConnection;
  isLoading: boolean;
  leaseType: string;
  selectedVariant: WordpressWpCar;
  trimType: string;
  variants: WordpressWpCarConnection;
  selectedAddons: {
    value: string;
    label: string;
    priceExcVat: number;
    priceIncVat: number;
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaseOptions);

import React from 'react';
import isNull from 'lodash/isNull';
import get from 'lodash/get';

import { useStaticQuery, graphql } from 'gatsby';

// Components
import RescueLargeLinks from './RescueLargeLinks';

// Type defs
import { WordpressWpBrand } from '../../defs/generated-models';

const Rescue: React.FunctionComponent<RescueProps> = (props) => {
  const { brand } = props;

  const placeholder = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "car-placeholder.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            height: 618
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const isRescueContent = brand && brand.acf && brand.acf.rescue_content;

  // Constuct the URL based on the heirarchy of lease builder page. Should always go up one level
  let staticTitle;
  let staticUrl;
  let staticButtonText;
  let staticImage;
  let isTopLevel;

  // Check for the grandparent
  if (
    brand &&
    brand.parent_element &&
    brand.parent_element.parent_element &&
    !isNull(brand.parent_element.parent_element)
  ) {
    staticTitle = brand.parent_element.parent_element.name;

    staticUrl = `/${
      brand &&
      brand.parent_element &&
      brand.parent_element.parent_element &&
      brand.parent_element.parent_element.brand_meta_fields &&
      brand.parent_element.parent_element.brand_meta_fields.slug
    }`;

    staticImage = get(
      brand,
      'parent_element.parent_element.acf.slides[0].image.localFile.childImageSharp.gatsbyImageData'
    );

    staticButtonText = `View more ${
      brand && brand.parent_element && brand.parent_element.parent_element.name
    }`;
  }

  // Fallback to the parent
  else if (brand && brand.parent_element && !isNull(brand.parent_element)) {
    staticUrl = `/${
      brand &&
      brand.parent_element &&
      brand.parent_element.brand_meta_fields &&
      brand.parent_element.brand_meta_fields.slug
    }`;

    staticTitle = brand.parent_element.name;

    staticButtonText = `View more ${brand.parent_element.name}`;

    staticImage = get(
      brand,
      'parent_element.acf.slides[0].image.localFile.childImageSharp.gatsbyImageData',
      placeholder.file.childImageSharp.gatsbyImageData
    );
  } else {
    isTopLevel = true;
    staticTitle = brand.name;
  }

  // Only return the rescue block if set in the CMS
  if (isRescueContent) {
    // The static block should always link back to the manufacturer page
    const staticItem = {
      buttonText: staticButtonText,
      image: staticImage,
      subtitle: 'View more of the',
      title: `${staticTitle} Range`,
      url: staticUrl,
      overlay: true,
    };

    // Prepare the additional items from the CMS
    const multiItems =
      brand.acf &&
      brand.acf.rescue_blocks &&
      brand.acf.rescue_blocks.map((item) => {
        return {
          buttonStyle: item && item.type,
          buttonText: item && item.link && item.link.title,
          buttonUrl: item && item.link && item.link.url,
          headline: (item && item.title) || '',
          image: get(
            item,
            'image.localFile.childImageSharp.gatsbyImageData',
            placeholder.file.childImageSharp.gatsbyImageData
          ),
          imageStyle: 'withEffect',
          subtitle: item && item.pre_title,
          overlay: item && item.overlay,
        };
      });

    return !isTopLevel ? (
      <RescueLargeLinks
        buttonText={staticItem.buttonText}
        buttonUrl={staticItem.url}
        headline={staticItem.title}
        image={staticItem.image}
        isMulti={multiItems && multiItems.length >= 1 ? true : false}
        multiItems={multiItems}
        overlay={staticItem.overlay}
        subtitle={staticItem.subtitle}
      />
    ) : null;
  }

  return null;
};

interface RescueProps {
  brand: WordpressWpBrand;
}

export default Rescue;

import React from 'react';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import clsx from 'clsx';

import InputLabel from './InputLabel';

const InputSelectVariant: React.FunctionComponent<InputSelectVariantProps> = (props) => {
  const {
    capitalize,
    disabled,
    fullWidth,
    handleChange,
    isPrimary,
    isTooltip,
    label,
    labelText,
    marginBottom,
    menuItems,
    name,
    required,
    tooltipContent,
    value,
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    select: {
      marginTop: 15,
      maxHeight: 300,
      fontWeight: 800,
      marginBottom: marginBottom ? marginBottom : 25,
      textTransform: capitalize ? 'capitalize' : 'none',
      '& svg': {
        display: menuItems.length <= 1 ? 'none' : 'block',
      },
    },
    primary: {
      color: theme.palette.primary.main,
    },
    item: {
      fontSize: 16,
      fontWeight: 600,
      textTransform: capitalize ? 'capitalize' : 'none',
    },
  }));

  const classes = useStyles();

  return (
    <FormControl fullWidth={fullWidth}>
      {label && labelText && (
        <InputLabel
          isTooltip={isTooltip}
          tooltipContent={tooltipContent}
          required={required || false}
        >
          {labelText}
        </InputLabel>
      )}
      <Select
        disabled={disabled || menuItems.length <= 1}
        name={name}
        className={clsx([classes.select, isPrimary && classes.primary])}
        onChange={(event) => {
          // @ts-ignore: The index will always exist
          handleChange(event.target.value);
        }}
        value={value}
      >
        {menuItems &&
          menuItems.map(
            (item, index) =>
              item && (
                <MenuItem
                  className={classes.item}
                  value={item.node.wordpress_id}
                  id={`${index}`}
                  key={`input-select-variant-${index}`}
                >
                  {item.node.post_meta_fields.title}
                </MenuItem>
              )
          )}
      </Select>
    </FormControl>
  );
};

interface InputSelectVariantProps {
  capitalize?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  handleChange: Function;
  isPrimary?: boolean;
  isTooltip?: boolean;
  label?: boolean;
  labelText?: string;
  marginBottom?: number;
  menuItems: any[];
  name: string;
  required?: boolean;
  tooltipContent?: string;
  value: string;
}

export default InputSelectVariant;

import React from 'react';
import { DatePicker, MuiPickersUtilsProvider, DateTimePickerProps } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { styleConfig } from '../../config/config';

import InputLabel from './InputLabel';

const InputDate: React.FunctionComponent<InputDateProps> = (props) => {
  const {
    onChange,
    label,
    labelText,
    value,
    fullWidth,
    required,
    marginBottom,
    isTooltip,
    tooltipContent,
  } = props;

  // The date today
  const today = new Date();

  const useStyles = makeStyles(() => ({
    root: {
      marginTop: 15,
      marginBottom: marginBottom ? marginBottom : 0,
    },
  }));

  const classes = useStyles(props);

  return (
    <Box className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {label && labelText && (
          <InputLabel
            isTooltip={isTooltip}
            tooltipContent={tooltipContent}
            required={required || false}
          >
            {labelText}
          </InputLabel>
        )}
        <DatePicker
          fullWidth={fullWidth}
          maxDate={new Date(today.setMonth(today.getMonth() + 12))}
          minDate={new Date()}
          onChange={onChange}
          openTo="year"
          value={value}
          variant="dialog"
          views={['year', 'month']}
          InputProps={{
            style: {
              color: styleConfig.color.text.gold,
              fontWeight: 800,
              marginBottom: marginBottom ? marginBottom : 0,
            },
          }}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
};

interface InputDateProps extends DateTimePickerProps {
  label?: boolean;
  labelText?: string;
  marginBottom?: number;
  fullWidth: boolean;
  value: Date;
  onChange: (date: any) => void;
  required?: boolean;
  isTooltip?: boolean;
  tooltipContent?: string;
}

export default InputDate;

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { Container, Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Config
import { styleConfig } from '../../../config/config';

// Components
import MuiButton from '../MuiButton';
import Title from '../Common/Title';
import SpecialOffersItem from '../SpecialOffersItem';
import Section from '../Common/Section';

// Type defs
import { WordpressWpBrand } from '../../defs/generated-models';

const SpecialOffers: React.FunctionComponent<SpecialOffersProps> = (props) => {
  const { brand, agreementTerms, annualMileage, initialRental } = props;

  const useStyles = makeStyles(() => ({
    footer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
    },
    footerTitle: {
      marginBottom: 50,
      color: styleConfig.color.text.black,
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      {brand && brand.acf && brand.acf.special_offers && (
        <Section>
          <Box className={classes.footerTitle}>
            <Title
              titleText="Special Offers"
              titleSize="h3"
              titleColor={styleConfig.color.text.darkgrey}
              titleCaps
              titleHeavy
              isSubtitle
              subtitleType="subtitle1"
              subtitleText="Discover Latest"
              alignment="center"
            />
          </Box>

          <Container maxWidth="xl">
            <Grid container spacing={3} justifyContent="center">
              {brand &&
                brand.acf &&
                brand.acf.special_offers &&
                brand.acf.special_offers.map((offer, index) => {
                  return (
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={`special-offers-${index}`}>
                      <SpecialOffersItem
                        agreementTerms={agreementTerms}
                        annualMileage={annualMileage}
                        initialRental={initialRental}
                        variant={offer}
                        isSearch={false}
                        index={index}
                      />
                    </Grid>
                  );
                })}
            </Grid>

            <footer className={classes.footer}>
              <Typography className={classes.footerTitle}>
                Initial payment & terms apply, subject to status
              </Typography>

              <Link to="/special-offers/">
                <MuiButton
                  aria-label="View all special offers"
                  name="special-offers"
                  variant="outlined"
                >
                  View all special offers
                </MuiButton>
              </Link>
            </footer>
          </Container>
        </Section>
      )}
    </React.Fragment>
  );
};

interface SpecialOffersProps {
  agreementTerms: number;
  annualMileage: number;
  initialRental: number;
  brand: WordpressWpBrand;
}

const mapStateToProps = (state) => {
  return {
    agreementTerms: state.brand.agreementTerms,
    annualMileage: state.brand.annualMileage,
    initialRental: state.brand.initialRental,
    brand: state.brand.brand,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SpecialOffers);

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Grid, Theme, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import * as Yup from 'yup';
import currency from 'currency.js';

import { styleConfig } from '../../../../../config/config';

import { tooltipValues } from '../../../../constants';

import InputSelect from '../../../InputSelect';
import InputText from '../../../InputText';
import MuiButton from '../../../MuiButton';

// Actions
import { submitQuote } from '../../../../actions/brand';

// Type defs
import { WordpressWpCar, WordpressWpBrand } from '../../../../defs/generated-models';
import { formatDate } from '../../../../utils/utils';

const StepThree: React.FunctionComponent<StepThreeProps> = (props) => {
  const {
    addonsTotal,
    agreementTerms,
    annualMileage,
    brand,
    deliveryDate,
    depositPrice,
    formData,
    fuelType,
    initialRental,
    isSubmit,
    isSubmitError,
    isSubmitting,
    leaseType,
    maintenance,
    monthlyPrice,
    selectedVariant,
    submitQuote,
    trim,
    selectedAddons,
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    title: {
      borderBottom: '1px #ddd solid',
      color: theme.palette.primary.dark,
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 30,
      paddingBottom: 20,
    },
    loadingRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 300,
    },
    loadingMessage: {
      fontFamily: 'Bai Jamjuree',
      fontSize: 20,
      fontWeight: 600,
      marginTop: 25,
    },
    errorTitle: {
      fontSize: 40,
      fontWeight: 600,
    },
    messageRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 300,
    },
    submitTitle: {
      fontSize: 40,
      fontWeight: 600,
      marginBottom: 15,
    },
    message: {
      fontSize: 20,
      fontWeight: 600,
      maxWidth: 540,
      textAlign: 'center',
      marginBottom: 60,

      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& a': {
        marginBottom: 30,
      },
    },

    footer: {
      marginTop: 100,
      '& button': {
        marginRight: 50,
      },
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',

      [theme.breakpoints.down('sm')]: {
        '& button': {
          width: '100%',
        },
      },
    },
    intro: {
      color: styleConfig.color.text.gray,
      marginBottom: 50,
    },
  }));

  const classes = useStyles();

  // Options for the title fields
  const titles = ['Please select', 'Mr', 'Mrs', 'Miss', 'Ms'];
  const tradingStyles = ['Limited Company', 'LLP', 'PLC', 'Partnership', 'Sole Trader'];

  // Field validation
  const numberRegExp = /^\(?0( *\d\)?){9,10}$/;

  // Validation schema for Formik
  const validationSchemaBusiness = Yup.object().shape({
    businessName: Yup.string().required(),
    tradingStyle: Yup.string().required(),
    yearsTrading: Yup.number().max(99).required(),
    title: Yup.string().oneOf(['Mr', 'Mrs', 'Miss', 'Ms']).required(),
    forename: Yup.string().required(),
    surname: Yup.string().required(),
    phoneNumber: Yup.string().matches(numberRegExp),
    email: Yup.string().email().required(),
  });

  // Validation schemas for Formik
  const validationSchemaPersonal = Yup.object().shape({
    title: Yup.string().oneOf(['Mr', 'Mrs', 'Miss', 'Ms']).required(),
    forename: Yup.string().required(),
    surname: Yup.string().required(),
    phoneNumber: Yup.string().matches(numberRegExp),
    email: Yup.string().email().required(),
  });

  const validationSchema =
    leaseType === 'personal' ? validationSchemaPersonal : validationSchemaBusiness;

  return (
    <Formik
      initialValues={{
        businessName: '',
        email: '',
        forename: '',
        phoneNumber: '',
        surname: '',
        title: 'Please select',
        tradingStyle: 'Limited Company',
        yearsTrading: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Get all the selected addons and prepare as key value pairs for the form submission
        const addonItems = selectedAddons.reduce(
          (acc, addon) => ({
            ...acc,
            [`Addon - ${addon.value}`]: `£${
              leaseType === 'business'
                ? Math.round(addon.priceExcVat / (agreementTerms + initialRental - 1))
                : Math.round(addon.priceIncVat / (agreementTerms + initialRental - 1))
            } pm`,
          }),
          {}
        );

        // The payload for the form submission along with lease data from the global state
        const payload = {
          ['Subject']: 'New Sales Enquiry',
          ['Title']: values.title,
          ['Forename']: values.forename,
          ['Surname']: values.surname,
          ['Phone Number']: values.phoneNumber,
          ['Email']: values.email,

          ['Business Name']: leaseType === 'business' ? values.businessName : 'n/a',
          ['Trading Style']: leaseType === 'business' ? values.tradingStyle : 'n/a',
          ['Years Trading']: leaseType === 'business' ? values.yearsTrading : 'n/a',

          ['Car']:
            brand && brand.parent_element && brand.parent_element.name && brand.parent_element.name
              ? `${brand.parent_element.name} ${brand.name} ${selectedVariant.title}`
              : '-',
          ['Trim']: trim,
          ['Fuel Type']: fuelType,
          ['Lease Type']: leaseType === 'business' ? 'Business' : 'Personal',
          ['Upfront Rental No.']: `${initialRental} Rentals`,
          ['Agreement Term']: `${agreementTerms} months`,
          ['Annual Mileage']: `${annualMileage} miles`,

          ['Deposit Price']: `${currency(depositPrice, { precision: 0, symbol: '£' }).format()}`,
          ['Monthly Price']: `${currency(monthlyPrice, { precision: 0, symbol: '£' }).format()}`,

          ['Delivery Date']: formatDate(deliveryDate, 'MMMM, YYYY'),
          ['Maintenance']: maintenance,

          ...addonItems,
          ['Addons Total']:
            addonItems !== 0
              ? `${currency(addonsTotal, { precision: 0, symbol: '£' }).format()} pm`
              : 'No addons selected',
        };

        return submitQuote(payload, values);
      }}
    >
      {({ handleChange, handleBlur, values, errors, setFieldValue }) => (
        <React.Fragment>
          {isSubmitError && (
            <Box className={classes.messageRoot}>
              <Typography color="error" component="h3" className={classes.errorTitle}>
                Error
              </Typography>
              <Typography className={classes.message}>
                There was an error with your request. Please try again later or get in touch
              </Typography>
            </Box>
          )}

          {isSubmit && (
            <Box className={classes.messageRoot}>
              <Typography id="form-enquiry-confirm" className={classes.submitTitle} component="h3">
                Thank you {formData.forename && formData.forename}{' '}
                {formData.surname && formData.surname}
              </Typography>

              <Typography className={classes.message} color="primary">
                A member of our team will respond to your enquiry as soon as possible.
              </Typography>

              <div className={classes.buttons}>
                <Link aria-label="Back to homepage" to="/">
                  <MuiButton aria-label="Back to homepage" name="homepage" variant="text">
                    Back to homepage
                  </MuiButton>
                </Link>
              </div>
            </Box>
          )}

          {!isSubmitting && !isSubmitError && !isSubmit && (
            <Form name="contact">
              <Typography variant="h4" component="div" className={classes.title}>
                Your contact details
              </Typography>

              {leaseType === 'business' && (
                <Grid container spacing={6}>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <InputText
                      disabled={isSubmitting}
                      error={typeof errors.businessName === 'string'}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      isLabel
                      isTooltip
                      labelText="Business Name"
                      marginBottom={25}
                      name="businessName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter name"
                      tooltipContent={tooltipValues.businessName}
                      type="text"
                      value={values.businessName}
                    />
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <InputSelect
                      disabled={isSubmitting}
                      fullWidth
                      handleChange={(val) => setFieldValue('tradingStyle', val)}
                      isTooltip
                      label
                      labelText="Type of business"
                      marginBottom={25}
                      menuItems={tradingStyles}
                      name="tradingStyle"
                      tooltipContent={tooltipValues.tradingStyle}
                      value={values.tradingStyle}
                    />
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <InputText
                      disabled={isSubmitting}
                      error={typeof errors.yearsTrading === 'string'}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      isLabel
                      isTooltip
                      labelText="Years Trading"
                      marginBottom={25}
                      name="yearsTrading"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter years"
                      tooltipContent={tooltipValues.yearsTrading}
                      type="number"
                      value={values.yearsTrading}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={6}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <InputSelect
                    disabled={isSubmitting}
                    error={typeof errors.title === 'string'}
                    fullWidth
                    handleChange={(val) => setFieldValue('title', val)}
                    isTooltip
                    label
                    labelText="Title"
                    marginBottom={25}
                    menuItems={titles}
                    name="title"
                    tooltipContent={tooltipValues.title}
                    value={values.title}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <InputText
                    disabled={isSubmitting}
                    error={typeof errors.forename === 'string'}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    isLabel
                    isTooltip
                    labelText="Forename"
                    marginBottom={25}
                    name="forename"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter forename"
                    tooltipContent={tooltipValues.forename}
                    type="string"
                    value={values.forename}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <InputText
                    disabled={isSubmitting}
                    error={typeof errors.surname === 'string'}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    isLabel
                    isTooltip
                    labelText="Surname"
                    marginBottom={25}
                    name="surname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter surname"
                    tooltipContent={tooltipValues.surname}
                    type="string"
                    value={values.surname}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <InputText
                    disabled={isSubmitting}
                    error={typeof errors.phoneNumber === 'string'}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    isLabel
                    isTooltip
                    labelText="Phone Number"
                    marginBottom={25}
                    name="phoneNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter phone number"
                    tooltipContent={tooltipValues.phoneNumber}
                    type="string"
                    value={values.phoneNumber}
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <InputText
                    disabled={isSubmitting}
                    error={typeof errors.email === 'string'}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    isLabel
                    isTooltip
                    labelText="Email"
                    marginBottom={25}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter email"
                    tooltipContent={tooltipValues.email}
                    type="string"
                    value={values.email}
                  />
                </Grid>
              </Grid>

              <div className={classes.btnWrapper}>
                <MuiButton
                  color="secondary"
                  aria-label="Get your quote"
                  disabled={isSubmitting}
                  height={61}
                  name="get-quote"
                  type="submit"
                  variant="contained"
                >
                  Get your quote
                </MuiButton>
              </div>
            </Form>
          )}
        </React.Fragment>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    // Lease data
    agreementTerms: state.brand.agreementTerms,
    brand: state.brand.brand,
    annualMileage: state.brand.annualMileage,
    depositPrice: state.brand.depositPrice,
    fuelType: state.brand.fuelType,
    initialRental: state.brand.initialRental,
    leaseType: state.brand.leaseType,
    monthlyPrice: state.brand.monthlyPrice,
    selectedVariant: state.brand.selectedVariant,
    trim: state.brand.trim,
    deliveryDate: state.brand.formData.deliveryDate,
    maintenance: state.brand.formData.maintenance,
    selectedAddons: state.brand.selectedAddons,
    addonsTotal: state.brand.addonsTotal,

    // Form state
    isSubmit: state.brand.isSubmit,
    isSubmitError: state.brand.isSubmitError,
    isSubmitting: state.brand.isSubmitting,

    formData: state.brand.formData,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({ submitQuote }, dispatch);

interface StepThreeProps {
  // Form data
  agreementTerms: number;
  annualMileage: number;
  depositPrice: number;
  fuelType: string;
  initialRental: number;
  leaseType: string;
  monthlyPrice: number;
  selectedVariant: WordpressWpCar;
  trim: string;
  deliveryDate: Date;
  maintenance: string;

  formData: {
    businessName: string;
    deliveryDate: Date;
    email: string;
    forename: string;
    maintenance: string;
    phoneNumber: string;
    surname: string;
    title: string;
    tradingStyle: string;
    yearsTrading: string;
  };

  addonsTotal: number;
  brand: WordpressWpBrand;

  selectedAddons: {
    isPOA: boolean;
    label: string;
    parent: string;
    priceExcVat: number;
    priceIncVat: number;
    value: string;
  }[];

  submitQuote: Function;

  isSubmit: boolean;
  isSubmitting: boolean;
  isSubmitError: boolean;
}

export default connect(mapStateToProps, mapDispatchToProps)(StepThree);

import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BreadcrumbsNavigation from './BreadcrumbsNavigation';
import { generateBreadcrumbs, generateBreadcrumbsSchema } from '../utils/utils';
import { BreadcrumbsType } from '../defs/custom';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F9F9F9',
    borderBottom: '1px solid #E5E5E5',
    paddingTop: 25,
    paddingBottom: 25,
  },
}));

const PageBreadcrumb: React.FC<PageBreadcrumbProps> = (props) => {
  const classes = useStyles();
  const { crumbs, location } = props;

  const breadcrumbsSchema = generateBreadcrumbsSchema(crumbs);
  const breadcrumbs = generateBreadcrumbs(crumbs);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumbsSchema)}</script>
      </Helmet>

      <div className={classes.root}>
        <Container maxWidth="xl">
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <BreadcrumbsNavigation crumbs={breadcrumbs} location={location} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

interface PageBreadcrumbProps {
  crumbs: BreadcrumbsType[];
  location: LocationType;
}

interface LocationType {
  pathname: string;
  search: string;
  hash: string;
  href: string;
  origin: string;
  protocol: string;
  host: string;
  hostname: string;
  port: string;
  state: State;
  key: string;
}

interface State {
  key: string;
}

export default PageBreadcrumb;

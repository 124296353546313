import React from 'react';
import { TextField, StandardTextFieldProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import clsx from 'clsx';

import InputLabel from './InputLabel';

const InputText: React.FunctionComponent<InputTextProps> = (props) => {
  const {
    isPrimary,
    marginBottom,
    isLabel,
    labelText,
    required,
    isTooltip,
    tooltipContent,
    ...rest
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      marginTop: 15,
      marginBottom: marginBottom ? marginBottom : 0,
    },
    inputLabel: {
      color: isPrimary ? theme.palette.primary.main : theme.palette.primary.dark,
    },
    inputPrimary: {
      '& input': {
        color: theme.palette.primary.main,
        '&::placeholder': {
          color: theme.palette.primary.dark,
        },
      },
      '& textarea': {
        color: theme.palette.primary.main,
        '&::placeholder': {
          color: theme.palette.primary.dark,
        },
      },
    },
  }));

  const classes = useStyles(props);

  return (
    <React.Fragment>
      {isLabel && labelText && (
        <InputLabel
          isTooltip={isTooltip}
          required={required || false}
          tooltipContent={tooltipContent}
        >
          {labelText}
        </InputLabel>
      )}

      <TextField className={clsx([classes.root, isPrimary && classes.inputPrimary])} {...rest} />
    </React.Fragment>
  );
};

interface InputTextProps extends StandardTextFieldProps {
  isPrimary?: boolean;
  marginBottom?: number;
  isLabel: boolean;
  labelText: string;
  isTooltip?: boolean;
  tooltipContent?: string;
  required?: boolean;
}

export default InputText;

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { Classes } from 'jss';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { withStyles, Container, Grid, Hidden } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';

// Config
import siteConfig from '../../config/site';

// Functions
import { decodeObjectSpecialCharacters } from '../utils/utils';

// Actions
import { getVariants, calculateVariantPrice, getDeals, handleResetData } from '../actions/brand';

// Models
import {
  WordpressWpCarConnection,
  WordpressWpBrand,
  WordpressWpCar,
} from '../defs/generated-models';

// Components
import ContentModules from '../components/ContentModules';
import CustomiseLease from '../components/LeaseBuilder/CustomiseLease/CustomiseLease';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ImageSlider from '../components/LeaseBuilder/ImageSlider';
import LayoutDefault from '../components/LayoutDefault';
import LeaseOptions from '../components/LeaseBuilder/LeaseOptions';
import Loading from '../components/LeaseBuilder/CustomiseLease/Steps/Loading';
import RecommendedDeals from '../components/LeaseBuilder/RecommendedDeals';
import Rescue from '../components/LeaseBuilder/Rescue';
import SelectedVariant from '../components/LeaseBuilder/CustomiseLease/SelectedVariant';
import SEO from '../components/SEO';
import SpecialOffers from '../components/LeaseBuilder/SpecialOffers';
import Specs from '../components/LeaseBuilder/Specs';
import TitleHeader from '../components/LeaseBuilder/CustomiseLease/TitleHeader/TitleHeader';
import PageBreadcrumb from '../components/PageBreadcrumb';

const styles = {
  pageWrapper: {
    backgroundColor: '#FFF',
  },
  sectionWrapper: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  loading: {
    minHeight: 300,
    height: 'calc(100vh - 289px - 104px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingPage: {
    minHeight: 800,
  },
};

class LeaseBuilderPage extends PureComponent<BrandPageProps> {
  componentWillUnmount() {
    // Cleanup and reset once leaving the page
    this.props.handleResetData();
  }

  componentDidMount() {
    this.props.handleResetData();

    // Add the static data to the global state
    this.props.getVariants(this.props.data.allWordpressWpCar, this.props.data.wordpressWpBrand);

    // Add the best deals to the global state
    if (this.props.data.wordpressWpBrand.acf && this.props.data.wordpressWpBrand.acf.best_deals) {
      this.props.getDeals(this.props.data.wordpressWpBrand.acf.best_deals);
    }
  }

  componentDidUpdate(prevProps) {
    // Recalculate the price of the variant if there is a change
    if (
      prevProps.leaseType !== this.props.leaseType ||
      prevProps.agreementTerms !== this.props.agreementTerms ||
      prevProps.initialRental !== this.props.initialRental ||
      prevProps.annualMileage !== this.props.annualMileage ||
      prevProps.variant !== this.props.variant ||
      prevProps.addonsTotal !== this.props.addonsTotal
    ) {
      // Check the price table exists before calculation
      if (
        this.props.variant &&
        this.props.variant.post_meta_fields &&
        this.props.variant.post_meta_fields.price_24m
      ) {
        this.props.calculateVariantPrice(
          this.props.leaseType,
          this.props.agreementTerms,
          this.props.initialRental,
          this.props.annualMileage,
          this.props.variant,
          this.props.addonsTotal
        );
      }
    }
  }

  render() {
    const { data, classes, isSubmit, isError, isLoading, isSubmitting } = this.props;
    const yoastMeta = decodeObjectSpecialCharacters(data.wordpressWpBrand.yoast_meta);
    const derivativesUnavailable = isEmpty(data.allWordpressWpCar.edges);

    if (isSubmitting) {
      return (
        <main>
          <Header />

          <div className={classes.loading}>
            <Loading message="Please wait..." />
          </div>

          <Footer />
        </main>
      );
    }

    const brandTitle =
      data &&
      data.wordpressWpBrand &&
      data.wordpressWpBrand.brand_meta_fields &&
      data.wordpressWpBrand.brand_meta_fields.brand_title;

    const capId = this.props.variant?.post_meta_fields?.capid;

    // Custom dynamic meta title for the lease builder page
    const brandMetaTitle = `${brandTitle} Leasing Deals | Morgan Highfield & Land`;
    const brandDesc = `Our ${brandTitle} Leasing know-how and all the latest deals without fees or charges - we are #1 for Prestige Car Leasing`;
    const defaultTitle = `${
      data.wordpressWpBrand.name || siteConfig.siteTitle
    } | Morgan Highfield & Land`;

    const location = this.props.location;

    const parentElement = this.props.brand.parent_element;

    const crumbs = [
      {
        pathname: '/',
        crumbLabel: 'Home',
      },
      {
        pathname: `/${parentElement?.slug}`,
        crumbLabel: parentElement?.name,
      },
      {
        pathname: parentElement?.parent_element?.slug
          ? `/${parentElement?.parent_element?.slug}`
          : undefined,
        crumbLabel: parentElement?.parent_element?.name,
      },
      {
        pathname: location.pathname,
        crumbLabel: data.wordpressWpBrand.name,
      },
    ];

    return (
      <main className={classes.pageWrapper}>
        <SEO
          description={yoastMeta.yoast_wpseo_metadesc || brandDesc || siteConfig.siteDescription}
          ogDesc={yoastMeta.yoast_wpseo_facebook_description}
          ogImg={yoastMeta.yoast_wpseo_facebook_image}
          ogTitle={yoastMeta.yoast_wpseo_facebook_title}
          title={brandMetaTitle || defaultTitle}
          twitterDesc={yoastMeta.yoast_wpseo_twitter_description}
          twitterImg={yoastMeta.yoast_wpseo_twitter_image}
          twitterTitle={yoastMeta.yoast_wpseo_twitter_title}
        />

        <Hidden only={['xl', 'lg']}>
          <Helmet
            bodyAttributes={{
              class: 'sticky',
            }}
          />
        </Hidden>

        <LayoutDefault>
          <TitleHeader
            title={
              this.props.data?.wordpressWpBrand?.acf?.title_custom ||
              this.props.data?.wordpressWpBrand?.brand_meta_fields?.brand_title ||
              ''
            }
            description={
              this.props.brand && this.props.brand.acf && this.props.brand.acf.description
            }
          />

          <PageBreadcrumb crumbs={crumbs} location={location} />

          {isSubmit ? (
            <CustomiseLease />
          ) : isLoading ? (
            <div className={clsx(classes.loading, classes.loadingPage)}>
              <Loading message="Loading ..." />
            </div>
          ) : (
            !isError &&
            !isLoading &&
            !derivativesUnavailable && (
              <React.Fragment>
                <Container maxWidth="xl" className={classes.sectionWrapper}>
                  <Grid container justifyContent="space-between" spacing={6}>
                    <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
                      {capId && <ImageSlider />}
                      <Specs />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <LeaseOptions />
                    </Grid>
                  </Grid>
                </Container>
              </React.Fragment>
            )
          )}

          {!isError && !isSubmit && (
            <React.Fragment>
              <RecommendedDeals />

              {data.wordpressWpBrand &&
                data.wordpressWpBrand.acf &&
                data.wordpressWpBrand.acf.content_modules_undefined &&
                data.wordpressWpBrand.acf.content_modules_undefined.map((module, index) => (
                  <ContentModules
                    key={`content-module-page-${index}`}
                    index={index}
                    module={module}
                  />
                ))}

              <React.Fragment>
                {!isError && !derivativesUnavailable && <CustomiseLease />}

                {!isError && <SpecialOffers />}

                <Rescue brand={this.props.data.wordpressWpBrand} />

                {!isError && !derivativesUnavailable && <SelectedVariant isFixed />}
              </React.Fragment>
            </React.Fragment>
          )}
        </LayoutDefault>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addonsTotal: state.brand.addonsTotal,
    agreementTerms: state.brand.agreementTerms,
    annualMileage: state.brand.annualMileage,
    brand: state.brand.brand,
    initialRental: state.brand.initialRental,
    isError: state.brand.isError,
    isLoading: state.brand.isLoading,
    isSubmit: state.brand.isSubmit,
    isSubmitError: state.brand.isSubmitError,
    isSubmitting: state.brand.isSubmitting,
    leaseType: state.brand.leaseType,
    variant: state.brand.selectedVariant,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getVariants, calculateVariantPrice, getDeals, handleResetData }, dispatch);

// Interfaces
interface BrandPageProps {
  // Actions
  getVariants: Function;
  getDeals: Function;
  handleResetData: Function;
  pageContext: any;

  // Global
  brand: WordpressWpBrand;
  addonsTotal: number;
  agreementTerms: number;
  annualMileage: number;
  calculateVariantPrice: Function;
  initialRental: number;
  isLoading: boolean;
  isError: boolean;
  leaseType: string;
  variant: WordpressWpCar;

  isSubmit: boolean;
  isSubmitting: boolean;
  isSubmitError: boolean;

  // Styles
  classes: Classes;

  // Gatsby source data
  data: {
    allWordpressWpCar: WordpressWpCarConnection;
    wordpressWpBrand: WordpressWpBrand;
  };

  location: any;
}

// Queries
export const query = graphql`
  query($id: Int!) {
    wordpressWpBrand(wordpress_id: { eq: $id }) {
      __typename
      wordpress_id
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      slug
      name
      page_meta_fields {
        title
      }
      brand_meta_fields {
        brand_title
        slug
      }
      acf {
        title_custom
      }
      parent_element {
        brand_meta_fields {
          slug
        }
        acf {
          title
          slides {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
        slug
        name
        wordpress_parent
        parent_element {
          brand_meta_fields {
            slug
          }
          acf {
            title
            slides {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 80
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
          }
          name
          slug
          wordpress_parent
        }
      }
      acf {
        content_modules_undefined {
          ... on WordPressAcf_multi_image_blocks {
            __typename
            title
            pre_title
            mib_rows {
              mib_columns {
                mib_title
                mib_link {
                  title
                  url
                  target
                }
                mib_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 80
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        width: 497
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_intro_features {
            __typename
            title
            description
            intro
          }
          ... on WordPressAcf_intro_steps {
            __typename
            id
            content {
              blockContent: content
              steps {
                content
                title
              }
            }
            overview {
              buttons {
                button {
                  title
                  target
                  url
                }
                type
              }
              pre_title
              title
            }
          }
          ... on WordPressAcf_magazine_blocks {
            __typename
            rows {
              cols {
                link {
                  url
                  title
                  target
                }
                contents
                subtext_colour
                subtitle
                text_align
                title
                title_colour
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 80
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        width: 800
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_special_offers {
            __typename
            id
            title
            pre_title
            tabs {
              title
              derivatives {
                guid
                post_meta_fields {
                  capid
                  price_24m {
                    mileage_8k
                    mileage_9k
                    mileage_10k
                    mileage_11k
                    mileage_12k
                    mileage_13k
                    mileage_14k
                    mileage_15k
                    mileage_16k
                    mileage_17k
                    mileage_18k
                    mileage_19k
                    mileage_20k
                  }
                  price_36m {
                    mileage_8k
                    mileage_9k
                    mileage_10k
                    mileage_11k
                    mileage_12k
                    mileage_13k
                    mileage_14k
                    mileage_15k
                    mileage_16k
                    mileage_17k
                    mileage_18k
                    mileage_19k
                    mileage_20k
                  }
                  price_48m {
                    mileage_8k
                    mileage_9k
                    mileage_10k
                    mileage_11k
                    mileage_12k
                    mileage_13k
                    mileage_14k
                    mileage_15k
                    mileage_16k
                    mileage_17k
                    mileage_18k
                    mileage_19k
                    mileage_20k
                  }
                  specs {
                    spec_BHP
                    spec_0_62
                    spec_MPG3
                    spec_CO2
                  }
                  special_offer
                  special_offer_info_bar
                  brand_title
                  title
                  variant_url
                }
              }
            }
          }
          ... on WordPressAcf_image_with_content {
            __typename
            id
            colour_mode
            image_align
            title
            blockContent: content
            image {
              alt_text
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    height: 673
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { cropFocus: CENTER }
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
          ... on WordPressAcf_accordion_faq {
            __typename
            id
            colour_mode
            title
            pre_title
            add_contact_button
            items {
              content_answer
              title_question
            }
          }
          ... on WordPressAcf_brand_blocks {
            __typename
            pre_title
            title
            rows {
              cols {
                link {
                  url
                  title
                  target
                }
                contents
                subtitle
                title
                description
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 80
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
            id
          }
          ... on WordPressAcf_reviews {
            __typename
            id
            reviews {
              post_title
              post_content
              acf {
                source
                rating
              }
            }
            pre_title
            title
            is_dark
          }
          ... on WordPressAcf_call_to_action {
            __typename
            buttons {
              type
              button {
                title
                target
                url
              }
            }
            pre_title
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: {
                      duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                    }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          ... on WordPressAcf_image_with_content_multiple {
            __typename
            id
            title
            submodules {
              colour_mode
              content
              image_align
              image {
                alt_text
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 80
                      height: 673
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          ... on WordPressAcf_intro {
            __typename
            id
            pre_title
            title
            intro
          }
          ... on WordPressAcf_general_content {
            __typename
            id
            main_content
            supporting_content {
              content
            }
          }
          ... on WordPressAcf_accordion_faq_review {
            __typename
            id
            items {
              content_answer
              title_question
            }
            title
            pre_title
            add_contact_button
            featured_review {
              post_content
              post_title
              acf {
                rating
              }
            }
          }
          ... on WordPressAcf_slider {
            __typename
            id
            title
            pre_title
            slides {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: {
                      duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                    }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          ... on WordPressAcf_video {
            __typename
            id
            youtube_video_id
          }
          ... on WordPressAcf_large_links {
            __typename
            id
            blocks {
              pre_title
              title
              type
              overlay
              link {
                title
                url
                target
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 80
                      height: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: {
                        duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                      }
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
        description
        image_override
        main_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                width: 618
                layout: CONSTRAINED
              )
            }
          }
        }
        supporting_images {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  width: 618
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        special_offers {
          post_title
          post_name
          post_meta_fields {
            variant_url
            title
            brands {
              slug
              name
            }
            special_offer
            special_offer_info_bar
            specs {
              spec_BHP
              spec_0_62
              spec_MPG3
              spec_CO2
            }
            price_24m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
            price_36m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
            price_48m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
          }
        }
        best_deals {
          post_title
          wordpress_id
          post_meta_fields {
            title
            price_24m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
            price_36m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
            price_48m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
          }
        }
        rescue_content
        rescue_blocks {
          overlay
          type
          pre_title
          title
          link {
            title
            url
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  transformOptions: {
                    duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                  }
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    allWordpressWpCar(sort: { fields: date, order: DESC }, filter: { brand: { eq: $id } }) {
      edges {
        node {
          id
          title
          wordpress_id
          slug
          title
          acf {
            title
            disable_addons
            special_offer
            special_offer_info_bar
          }
          post_meta_fields {
            capid
            title
            trim
            fuel
            brands {
              name
            }
            specs {
              spec_BHP
              spec_0_62
              spec_MPG3
              spec_CO2
            }
            price_24m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
            price_36m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
            price_48m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
          }
        }
      }
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeaseBuilderPage));

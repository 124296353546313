import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import get from 'lodash/get';
import { useStaticQuery, graphql } from 'gatsby';

import { styleConfig } from '../../../config/config';

import Loading from './CustomiseLease/Steps/Loading';

const useStyles = makeStyles((theme: Theme) => ({
  thumbnail: {
    cursor: 'pointer',
    width: 133,
    height: 85,
    border: '2px #DDD solid',
    margin: 0,
    color: 'transparent',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  thumbnailActive: {
    borderColor: styleConfig.color.text.gold,
  },
  thumbnailItem: {
    marginBottom: 10,
    display: 'flex',
    marginRight: 10,
  },
  thumbNailButton: {
    background: 'none',
    padding: 0,
    border: 0,
  },
  thumbnailWrapper: {
    marginTop: 25,
    display: 'flex',
    flexWrap: 'wrap',

    '& img': {
      marginBottom: 10,
    },

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
      '& > img': {
        marginBottom: 10,
      },
    },
  },
  wrapper: {
    position: 'relative',
    minHeight: 387,

    [theme.breakpoints.down('lg')]: {
      // minHeight: 387,
    },

    [theme.breakpoints.down('md')]: {
      minHeight: 245,
    },

    [theme.breakpoints.down('sm')]: {
      // minHeight: 359,
      objectFit: 'cover',
    },
  },
}));

const ImageSlider: React.FunctionComponent<ImageSliderProps> = (props) => {
  const [activeImage, setActiveImage] = useState();
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [activeVariantId, setActiveVariantId] = useState<number | null>();
  const capId = props.selectedVariant.post_meta_fields.capid;

  const placeholder = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "variant-ph.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            height: 618
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const classes = useStyles();
  const { brand, isLoading, selectedVariant } = props;

  const baseImageUrl = `https://cdn.imagin.studio/getImage?customer=${process.env.GATSBY_SITE_IMAGIN_ID}&capVehicleType=car&capId=${capId}&randomPaint=false&countryCode=GB&fileType=webp`;

  const image1 = `${baseImageUrl}&angle=01`;
  const image2 = `${baseImageUrl}&angle=05`;
  const image3 = `${baseImageUrl}&angle=23`;
  const image4 = `${baseImageUrl}&angle=13`;
  const image5 = `${baseImageUrl}&angle=17`;
  const image6 = `${baseImageUrl}&angle=21`;
  const image7 = `${baseImageUrl}&angle=22`;

  const imagesFull = React.useMemo(() => {
    return [image1, image2, image3, image4, image5, image6, image7];
  }, [image1, image2, image3, image4, image5, image6, image7]);

  const isBrandOverride = brand && brand.acf && brand.acf && brand.acf.image_override;
  let overrideImages;

  if (isBrandOverride) {
    const overrideThumbnails =
      (brand &&
        brand.acf &&
        brand.acf.supporting_images &&
        brand.acf.supporting_images.map((thumbnail) => {
          return get(thumbnail, 'image.localFile.childImageSharp.gatsbyImageData');
        })) ||
      [];

    overrideImages = [
      ...[get(brand, 'acf.main_image.localFile.childImageSharp.gatsbyImageData')],
      ...overrideThumbnails,
    ];
  }

  const thumbnails = isBrandOverride ? overrideImages : imagesFull;

  // TODO: Change images on route change
  useEffect(() => {
    // Reset the selected thumbnail to the first if the selected variant changes
    if (activeVariantId !== selectedVariant.wordpress_id) {
      let defaultActiveImage;

      if (brand.acf.image_override) {
        defaultActiveImage = overrideImages[0];
      } else if (imagesFull && imagesFull.length > 0 && imagesFull) {
        defaultActiveImage = imagesFull[0];
      } else {
        defaultActiveImage = placeholder.file.childImageSharp.gatsbyImageData;
      }

      setActiveImageIndex(0);
      setActiveImage(defaultActiveImage);
    }

    if (selectedVariant) {
      // Set the active variant ID to check against
      setActiveVariantId(selectedVariant.wordpress_id);
    }
  }, [
    activeVariantId,
    selectedVariant,
    overrideImages,
    brand.acf.image_override,
    imagesFull,
    placeholder.file.childImageSharp.gatsbyImageData,
  ]);

  function handleChange(index) {
    // Do nothing if the image is already selected
    if (activeImageIndex === index) {
      return;
    }

    setTimeout(() => {
      setActiveImage(
        (isBrandOverride && overrideImages && overrideImages[index]) ||
          (imagesFull && imagesFull[index])
      );
      setActiveImageIndex(index);
    }, 500);
  }

  if (!capId) {
    return null;
  }

  return (
    <React.Fragment>
      <Box className={classes.wrapper}>
        {isLoading ? (
          <div className={classes.loading}>
            <Loading message="Loading.." />
          </div>
        ) : (
          <img loading="lazy" src={activeImage} alt={selectedVariant?.post_meta_fields?.title} />
        )}
      </Box>

      {thumbnails && thumbnails.length > 1 && (
        <Box className={classes.thumbnailWrapper}>
          {thumbnails.map(
            (thumbnail, index) =>
              thumbnail && (
                <div key={`lb-thumbnail-${capId}${index}`} className={classes.thumbnailItem}>
                  <button
                    className={classes.thumbNailButton}
                    type="button"
                    onClick={() => handleChange(index)}
                  >
                    <img
                      loading="lazy"
                      src={thumbnail || placeholder.file.childImageSharp.gatsbyImageData}
                      alt={`${selectedVariant?.post_meta_fields?.title} thumbnail ${index + 1}`}
                      className={
                        activeImageIndex === index
                          ? clsx([classes.thumbnail, classes.thumbnailActive])
                          : classes.thumbnail
                      }
                    />
                  </button>
                </div>
              )
          )}
        </Box>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    brand: state.brand.brand,
    isLoading: state.brand.isLoadingVariant,
    selectedVariant: state.brand.selectedVariant,
  };
};

interface ImageSliderProps {
  brand: any; // TODO: Update WordpressWpBrand
  isLoading: boolean;
  selectedVariant: any; // WordpressWpCar;
}

export default connect(mapStateToProps)(ImageSlider);

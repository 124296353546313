import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const BreadcrumbsNavigation: React.FC<BreadcrumbsNavigationProps> = (props) => {
  const { crumbs, location } = props;

  if (!crumbs) {
    return null;
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      {crumbs.map((crumb) => {
        if (!crumb.crumbLabel && !crumb.pathname) {
          return null;
        }

        if (crumb.pathname === location.pathname || (!crumb.pathname && crumb.crumbLabel)) {
          return <Typography key={location.pathname}>{crumb.crumbLabel}</Typography>;
        }

        if (crumb.pathname) {
          return (
            <Link
              component={GatsbyLink}
              key={location.pathname}
              color="primary"
              to={crumb.pathname}
            >
              {crumb.crumbLabel}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
};

interface BreadcrumbsType {
  pathname: string | undefined;
  crumbLabel: string | null | undefined;
}

interface BreadcrumbsNavigationProps {
  crumbs: BreadcrumbsType[];
  location: LocationType;
}

interface LocationType {
  pathname: string;
  search: string;
  hash: string;
  href: string;
  origin: string;
  protocol: string;
  host: string;
  hostname: string;
  port: string;
  state: State;
  key: string;
}

interface State {
  key: string;
}

export default BreadcrumbsNavigation;

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme: Theme) => ({
  pageRoot: {
    [theme.breakpoints.down('md')]: {
      paddingTop: 105,
    },

    [theme.breakpoints.down('sm')]: {
      paddingTop: 85,
    },
  },
}));

const LayoutDefault: React.FunctionComponent<LayoutDefaultProps> = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <Header />
      <div className={classes.pageRoot}>{children}</div>
      <Footer />
    </React.Fragment>
  );
};

interface LayoutDefaultProps {
  children: React.ReactNode;
}

export default LayoutDefault;

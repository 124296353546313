import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  initialRentalMarks,
  agreementTermsMarks,
  annualMileageMarks,
  tooltipValues,
} from '../../../../constants';

import InputDate from '../../../InputDate';
import InputRadio from '../../../InputRadio';
import InputRange from '../.../../../../InputRange';
import InputSelect from '../../../InputSelect';

// Actions
import {
  handleUpdateAgreementTerms,
  handleUpdateInitialRental,
  handleUpdateLeaseType,
  handleUpdateAnnualMileage,
  handleUpdateFormData,
  handleUpdateAddons,
} from '../../../../actions/brand';
import { styleConfig } from '../../../../../config/config';

const useStyles = makeStyles((theme) => ({
  title: {
    borderBottom: '1px #ddd solid',
    color: theme.palette.primary.dark,
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 30,
    paddingBottom: 20,

    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },
  intro: {
    color: styleConfig.color.text.gray,
    marginBottom: 50,

    [theme.breakpoints.down('xs')]: {
      marginBottom: 25,
    },
  },
}));

const StepOne: React.FunctionComponent<StepOneProps> = (props) => {
  const {
    agreementTerms,
    annualMileage,
    deliveryDate,
    groupOptions,
    handleUpdateAddons,
    handleUpdateAgreementTerms,
    handleUpdateAnnualMileage,
    handleUpdateFormData,
    handleUpdateInitialRental,
    handleUpdateLeaseType,
    initialRental,
    isLoading,
    leaseType,
    maintenance,
    selectedAddons,
  } = props;

  const classes = useStyles();

  const leaseTypeMenuItems = ['business', 'personal'];
  const carMaintenanceOptions = [
    { label: 'No', value: 'No' },
    { label: 'Yes', value: 'Yes' },
  ];

  function handleLeaseType(val) {
    handleUpdateLeaseType(val.toLowerCase());

    // Update the addon items and price if they are already set
    if (!isEmpty(selectedAddons)) {
      handleUpdateAddons(
        selectedAddons,
        val.toLowerCase(),
        agreementTerms,
        initialRental,
        groupOptions
      );
    }
  }

  function handleRental(val) {
    if (val !== initialRental) {
      // Update the addon items and price if they are already set
      if (!isEmpty(selectedAddons)) {
        handleUpdateAddons(selectedAddons, leaseType, agreementTerms, val, groupOptions);
      }

      return handleUpdateInitialRental(val);
    }

    return;
  }

  function handleTerms(val) {
    if (val !== agreementTerms) {
      // Update the addon items and price if they are already set
      if (!isEmpty(selectedAddons)) {
        handleUpdateAddons(selectedAddons, leaseType, val, initialRental, groupOptions);
      }

      return handleUpdateAgreementTerms(val);
    }

    return;
  }

  function handleMileage(val) {
    if (val !== annualMileage) {
      return handleUpdateAnnualMileage(val);
    }

    return;
  }

  function handleDelivery(val) {
    handleUpdateFormData('deliveryDate', val);
  }

  function handleCarMaintainance(val) {
    handleUpdateFormData('maintenance', val);
  }

  return (
    <React.Fragment>
      <Typography variant="h4" component="div" className={classes.title}>
        Customise my lease
      </Typography>

      <Grid container spacing={6}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <InputRange
            handleChange={handleMileage}
            isTooltip
            labelText="Annual Mileage"
            marginBottom={25}
            marks={annualMileageMarks}
            max={20000}
            min={8000}
            name="annualMileage"
            step={1000}
            tooltipContent={tooltipValues.annualMileage}
            value={annualMileage}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <InputSelect
            capitalize
            disabled={isLoading}
            fullWidth
            handleChange={handleLeaseType}
            isPrimary
            isTooltip
            label
            labelText="Lease Type"
            marginBottom={25}
            menuItems={leaseTypeMenuItems}
            name="leaseType"
            tooltipContent={tooltipValues.leaseType}
            value={leaseType}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <InputRange
            handleChange={handleTerms}
            isTooltip
            labelText="Agreement Term (Months)"
            marginBottom={25}
            marks={agreementTermsMarks}
            max={48}
            min={24}
            name="agreementTerms"
            step={12}
            tooltipContent={tooltipValues.agreementTerms}
            value={agreementTerms}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <InputDate
            fullWidth
            isTooltip
            label
            labelText="When is delivery required?"
            marginBottom={25}
            onChange={handleDelivery}
            tooltipContent={tooltipValues.deliveryDate}
            value={deliveryDate}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <InputRange
            handleChange={handleRental}
            isTooltip
            labelText="Initial Rental (Months)"
            marginBottom={25}
            marks={initialRentalMarks}
            max={12}
            min={3}
            name="initialRental"
            step={3}
            tooltipContent={tooltipValues.initialRental}
            value={initialRental}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <InputRadio
            handleChange={handleCarMaintainance}
            isTooltip
            label
            labelText="Do you require car maintenance with your lease?"
            options={carMaintenanceOptions}
            tooltipContent={tooltipValues.maintenance}
            value={maintenance}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    agreementTerms: state.brand.agreementTerms,
    annualMileage: state.brand.annualMileage,
    deliveryDate: state.brand.formData.deliveryDate,
    groupOptions: state.brand.groupOptions,
    initialRental: state.brand.initialRental,
    isLoading: state.brand.isLoadingVariant,
    leaseType: state.brand.leaseType,
    maintenance: state.brand.formData.maintenance,
    selectedAddons: state.brand.selectedAddons,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleUpdateAddons,
      handleUpdateAgreementTerms,
      handleUpdateAnnualMileage,
      handleUpdateFormData,
      handleUpdateInitialRental,
      handleUpdateLeaseType,
    },
    dispatch
  );

interface StepOneProps {
  agreementTerms: number;
  annualMileage: number;
  deliveryDate: Date;
  groupOptions: {
    label: string;
    options: {
      isPOA: boolean;
      label: string;
      parent: string;
      priceExcVat: number;
      priceIncVat: number;
      value: string;
    }[];
  }[];
  handleUpdateAgreementTerms: Function;
  handleUpdateAnnualMileage: Function;
  handleUpdateFormData: Function;
  handleUpdateInitialRental: Function;
  handleUpdateLeaseType: Function;
  handleUpdateAddons: Function;
  initialRental: number;
  isLoading: boolean;
  leaseType: string;
  maintenance: string;
  selectedAddons: {
    value: string;
    label: string;
    priceExcVat: number;
    priceIncVat: number;
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);

import React from 'react';
import { Grid, Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { GatsbyImage } from 'gatsby-plugin-image';

import { styleConfig } from '../../../config/config';

import MuiButton from '../MuiButton';
import RouteLink from '../RouteLink';
import Section from '../Common/Section';
import Title from '../Common/Title';

const RescueLargeLinks: React.FunctionComponent<RescueLargeLinksProps> = (props) => {
  const {
    buttonText,
    buttonUrl,
    headline,
    image,
    imageStyle,
    isMulti,
    multiItems,
    overlay,
    subtitle,
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      position: 'relative',
      overflow: 'hidden',
    },
    cta: {
      position: 'relative',
      overflow: 'hidden',
      background: styleConfig.color.bg.black,

      [theme.breakpoints.up('md')]: {
        height: 900,
      },
    },
    vc: {
      width: '100%',
      zIndex: 5,
      position: 'absolute',
      top: '50%',
      transform: 'translate3d(0, -50%, 0)',
      willChange: 'transform',
    },
    image: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      display: 'block',
      height: '100%',
      width: '100%',
      overflow: 'hidden',

      '&:before, &:after': {
        content: `''`,
        position: 'absolute',
        zIndex: 1,
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        display: 'block',
      },

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center',
        opacity: isMulti && imageStyle ? 0.5 : 0.6,
      },
    },
    withEffect: {
      position: 'relative',

      '&:before': {
        zIndex: 2,
        backgroundImage:
          'linear-gradient(-39deg, rgba(198,161,92,0.00) 50%, rgba(198,161,92,0.80) 100%)',
        opacity: 0.8,
      },
      '&:after': {
        backgroundImage: 'linear-gradient(206deg, rgba(0,0,0,0.00) 5%, rgba(0,0,0,0.75) 100%);',
        opacity: 1,
      },
    },
    inner: {
      position: 'relative',
      textAlign: 'left',
      padding: '6rem 0',

      display: 'block',

      [theme.breakpoints.down('sm')]: {
        padding: '4rem',
      },

      '& h3': {
        textTransform: 'uppercase',
        marginBottom: '4rem',
      },

      '& h4': {
        textTransform: 'uppercase',
        marginBottom: '4rem',
      },
    },

    multiCta: {
      display: 'block',
      position: 'relative',
      overflow: 'hidden',
      background: styleConfig.color.bg.black,
      height: 900,

      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },

    multiCtaItem: {
      position: 'relative',
      display: 'block',
      height: 450,

      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        display: 'block',
      },

      [theme.breakpoints.up('md')]: {
        height: 450,
      },
    },
    noPadding: {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      {isMulti && multiItems && multiItems.length === 2 ? (
        <Section nopad className={classes.root}>
          <Grid container>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.multiCta}>
                <div className={classes.vc}>
                  <Grid container justifyContent="center">
                    <Grid item sm={10}>
                      <Box className={classes.inner}>
                        <Title
                          component="div"
                          isSubtitle
                          marginBottom={20}
                          subtitleText={subtitle}
                          subtitleType="subtitle1"
                          titleColor="white"
                          titleHeavy
                          titleSize="h3"
                          titleText={headline}
                        />
                        <RouteLink href={`${buttonUrl}`}>
                          <MuiButton aria-label={buttonText} name="view-brand" variant="contained">
                            <span dangerouslySetInnerHTML={{ __html: buttonText }} />
                          </MuiButton>
                        </RouteLink>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
                <GatsbyImage
                  image={image}
                  className={clsx(overlay && classes.withEffect, classes.image)}
                  alt={headline}
                  loading="lazy"
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.multiCta}>
                {multiItems.map((block, index) => (
                  <div key={index} className={classes.multiCtaItem}>
                    <div className={classes.vc}>
                      <Grid container justifyContent="center">
                        <Grid item sm={10}>
                          <Box className={classes.inner}>
                            <Title
                              component="div"
                              isSubtitle
                              marginBottom={20}
                              subtitleText={block.subtitle}
                              subtitleType="subtitle1"
                              titleColor="white"
                              titleHeavy
                              titleSize="h3"
                              titleText={block.headline}
                            />

                            <RouteLink href={`${block.buttonUrl}`}>
                              <MuiButton
                                aria-label={block.buttonText}
                                name="view-car-brand"
                                labelColor="#FFF"
                                variant={block.type === 'block' ? 'contained' : 'outlined'}
                              >
                                <div dangerouslySetInnerHTML={{ __html: block.buttonText }} />
                              </MuiButton>
                            </RouteLink>
                          </Box>
                        </Grid>
                      </Grid>
                    </div>

                    {block.image && (
                      <GatsbyImage
                        image={block.image}
                        className={clsx(block.overlay && classes.withEffect, classes.image)}
                        alt={block.headline}
                        loading="lazy"
                      />
                    )}
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Section>
      ) : (
        <Section nopad>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className={classes.cta}>
                <div className={classes.vc}>
                  <Grid container justifyContent="center">
                    <Grid item sm={10}>
                      <Box className={classes.inner}>
                        <Title
                          component="div"
                          isSubtitle
                          marginBottom={40}
                          subtitleText={subtitle}
                          subtitleType="subtitle1"
                          titleColor="white"
                          titleHeavy
                          titleSize="h2"
                          titleText={headline}
                        />
                        <RouteLink aria-label={subtitle} href={`${buttonUrl}`}>
                          <MuiButton
                            aria-label={subtitle}
                            labelColor="#FFF"
                            name="rescue-car-brand-link"
                            variant="contained"
                          >
                            <div dangerouslySetInnerHTML={{ __html: buttonText }} />
                          </MuiButton>
                        </RouteLink>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
                <GatsbyImage
                  image={image}
                  className={clsx(overlay && classes.withEffect, classes.image)}
                  alt={headline}
                  loading="lazy"
                />
              </div>
            </Grid>
          </Grid>
        </Section>
      )}
    </React.Fragment>
  );
};

interface RescueLargeLinksProps {
  buttonText: string;
  buttonUrl: string;
  headline: string;
  image: any;
  imageStyle?: string;
  isMulti?: boolean;
  multiItems: any;
  overlay: boolean;
  subtitle: string;
}

export default RescueLargeLinks;

import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import InputLabel from './InputLabel';

const InputRadio: React.FunctionComponent<InputRadioProps> = (props) => {
  const {
    value,
    handleChange,
    options,
    labelText,
    label,
    isTooltip,
    tooltipContent,
    required,
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        marginBottom: 25,
      },
    },
    inputLabel: {
      marginLeft: 0,
      marginRight: 50,
      ' & > span': {
        padding: '9px 0',
        marginRight: 10,
        fontWeight: 600,
      },
    },
    radioGroup: {
      marginTop: 30,
    },
  }));

  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.root}>
      {label && labelText && (
        <InputLabel
          isTooltip={isTooltip}
          required={required || false}
          tooltipContent={tooltipContent}
        >
          {labelText}
        </InputLabel>
      )}
      <RadioGroup
        aria-label="position"
        className={classes.radioGroup}
        name="position"
        onChange={(event, value) => handleChange(value)}
        row
        value={value}
      >
        {options &&
          options.map((option, index) => (
            <FormControlLabel
              className={classes.inputLabel}
              control={<Radio checked={option.value === value} color="primary" />}
              key={`input-radio-${index}`}
              label={option.label}
              labelPlacement="end"
              value={option.value}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};

interface InputRadioProps {
  value: string;
  marginBottom?: boolean;
  label: boolean;
  labelText: string;
  options: {
    label: string;
    value: string;
  }[];
  handleChange: Function;
  isTooltip?: boolean;
  tooltipContent?: string;
  required?: boolean;
}

export default InputRadio;

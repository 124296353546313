import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Grid, Paper, Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import EnquiryForm from './StepOne';
import MuiButton from '../../../MuiButton';
import StepProgress from './StepsProgress';
import StepThree from './StepThree';
import StepTwo from './StepTwo';

import { WordpressWpCar } from '../../../../defs/generated-models';

import { handleActiveStep } from '../../../../actions/brand';

const Steps: React.FunctionComponent<StepsProps> = (props) => {
  const {
    activeStep,
    selectedVariant,
    handleActiveStep,
    isSubmit,
    isSubmitting,
    isError,
    isLoadingAddons,
    isLoadingVariant,
  } = props;

  // Styles
  const useStyles = makeStyles((theme: Theme) => ({
    formRoot: {
      position: 'relative',
    },
    paper: {
      marginBottom: 50,
    },
    formWrapper: {
      padding: 50,
      marginBottom: 125,
      minHeight: 350,

      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },

      [theme.breakpoints.down('sm')]: {
        padding: 25,
        marginBottom: 175,
      },
    },

    footer: {
      width: '100%',
      marginTop: 50,
      position: 'absolute',
      padding: '0 50px',
      bottom: 50,
      right: 0,
      display: 'flex',
      justifyContent: activeStep === 1 ? 'space-between' : 'flex-end',

      [theme.breakpoints.down('md')]: {
        bottom: 'auto',
      },

      '& button': {
        marginRight: 50,

        [theme.breakpoints.down('sm')]: {
          marginBottom: 25,
          marginRight: 0,
        },

        '&:last-of-type': {
          marginRight: 0,
        },
      },

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  }));

  const classes = useStyles();

  const isAddonsDisabled =
    selectedVariant && selectedVariant.acf && selectedVariant.acf.disable_addons ? true : false;

  function getSteps() {
    return [
      'Start your customisation',
      isAddonsDisabled ? 'No addons available' : 'Choose your options',
      'Confirm enquiry',
    ];
  }

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <EnquiryForm />;
      case 1:
        return <StepTwo />;
      case 2:
        return <StepThree />;
      default:
        return null;
    }
  }

  function handleNext() {
    if (isAddonsDisabled) {
      handleActiveStep(2);
    } else {
      handleActiveStep(activeStep + 1);
    }
  }

  function handleBack() {
    if (isAddonsDisabled) {
      handleActiveStep(0);
    } else {
      handleActiveStep(activeStep - 1);
    }
  }

  const handleStep = (step: number) => () => {
    handleActiveStep(step);
  };

  return (
    <Container maxWidth="xl">
      <StepProgress
        steps={getSteps()}
        activeStep={activeStep}
        handleStep={handleStep}
        isAddonsDisabled={isAddonsDisabled}
      />

      <Paper>
        <Grid container justifyContent="center">
          <Grid item xl={10} lg={10} md={12} sm={12} xs={12} className={classes.formRoot}>
            <Box className={classes.formWrapper}>
              {getStepContent(activeStep)}

              {!isSubmitting && !isSubmit && !isError && (
                <Box component="footer" className={classes.footer}>
                  {activeStep >= 1 && (
                    <MuiButton
                      aria-label="Go back"
                      disabled={isLoadingAddons || isLoadingVariant}
                      name="go-back"
                      onClick={handleBack}
                      variant="text"
                    >
                      <ChevronLeftIcon /> Go back
                    </MuiButton>
                  )}

                  {activeStep <= 1 && (
                    <MuiButton
                      aria-label="Continue"
                      color="secondary"
                      disabled={isLoadingAddons || isLoadingVariant}
                      name="continue"
                      onClick={handleNext}
                      variant="contained"
                    >
                      Continue <ChevronRightIcon />
                    </MuiButton>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    activeStep: state.brand.activeStep,
    isError: state.brand.isSubmitError,
    isSubmit: state.brand.isSubmit,
    isSubmitting: state.brand.isSubmitting,
    selectedVariant: state.brand.selectedVariant,
    isLoadingAddons: state.brand.isLoadingAddons,
    isLoadingVariant: state.brand.isLoadingVariant,
    isErrorAddons: state.brand.isErrorAddons,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ handleActiveStep }, dispatch);

interface StepsProps {
  activeStep: number;
  isError: boolean;
  isSubmit: boolean;
  isSubmitting: boolean;
  selectedVariant: WordpressWpCar;
  handleActiveStep: Function;
  isLoadingAddons: boolean;
  isLoadingVariant: boolean;
  isErrorAddons: boolean;
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);

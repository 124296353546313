import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Theme, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { rgba } from 'polished';
import isEmpty from 'lodash/isEmpty';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';

import Loading from './Loading';

import { getAddons, handleUpdateAddons } from '../../../../actions/brand';

import { styleConfig } from '../../../../../config/config';

const animatedComponents = makeAnimated();

const StepTwo = (props) => {
  const theme = useTheme<Theme>();

  const colourStyles = {
    menuList: (styles) => ({
      ...styles,
      maxHeight: 600,
    }),
    control: (styles) => ({
      ...styles,
      '& > div': {
        padding: 0,
      },
      marginTop: 10,
      border: 'none',
      borderBottom: '1px #DDD solid',
      padding: 0,
      outline: 0,
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#DDD',
      },
      borderRadius: 0,
      borderWidth: 2,
      paddingBottom: 10,
    }),
    menu: (styles) => {
      return {
        ...styles,
        zIndex: 999,
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: styleConfig.color.bg.gold,
        color: '#FFF',
        padding: 5,
        fontSize: 16,
        fontWeight: 600,
        borderRadius: 0,

        [theme.breakpoints.down('md')]: {
          fontSize: 14,
        },
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#FFF',
    }),

    multiValueRemove: (styles) => ({
      ...styles,
      padding: 0,
      svg: {
        width: 20,
        height: 25,
        fill: rgba(styleConfig.color.text.darkgrey, 0.4),
        transition: '250ms ease-in-out all',
      },
      ':hover': {
        cursor: 'pointer',
        svg: {
          fill: styleConfig.color.text.darkgrey,
        },
      },
    }),
    group: (styles) => ({
      ...styles,
      backgroundColor: '#FAFAFA',
      padding: '30px 0',
      borderBottom: '1px #DDD solid',
      '&:nth-of-type(odd)': {
        backgroundColor: '#FEFEFE',
      },
    }),
    groupHeading: (styles) => ({
      ...styles,
      fontSize: 17,
      fontWeight: 600,
      fontFamily: 'Bai Jamjuree',
      color: styleConfig.color.text.gold,
      textTransform: 'uppercase',
      marginBottom: 20,
      position: 'relative',
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: 16,

      [theme.breakpoints.down('md')]: {
        fontSize: 13,
      },
    }),
    option: (styles, state) => ({
      ...styles,
      color: styleConfig.color.text.darkgrey,
      cursor: 'pointer',
      padding: 10,
      fontWeight: 600,
      fontSize: 14,
      backgroundColor: state.isSelected ? styleConfig.color.bg.gold : 'none',
      '&:hover': {
        backgroundColor: styleConfig.color.bg.gold,
        color: '#FFF',
      },

      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    }),
  };

  const {
    brand,
    isLoading,
    isError,
    agreementTerms,
    handleUpdateAddons,
    groupOptions,
    getAddons,
    initialRental,
    selectedVariant,
    selectedAddons,
    leaseType,
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    title: {
      borderBottom: '1px #ddd solid',
      color: theme.palette.primary.dark,
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 50,
      paddingBottom: 20,
    },
    label: {
      color: '#2b2b2b',
      fontSize: 14,
      fontWeight: 800,
    },
    skeleton: {
      marginTop: 10,
    },
    error: {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 20,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    // Only get the addons if they have not been requested already
    if (isEmpty(groupOptions)) {
      return getAddons(selectedVariant.wordpress_id, agreementTerms, initialRental, leaseType);
    }

    return;
  }, [
    agreementTerms,
    getAddons,
    groupOptions,
    initialRental,
    leaseType,
    selectedVariant.wordpress_id,
  ]);

  const handleChange = (val) => {
    handleUpdateAddons(val, leaseType, agreementTerms, initialRental);
  };

  if (isLoading) {
    return <Loading message="Fetching addons" />;
  }

  if (isError) {
    return (
      <div>
        <Typography className={classes.error}>
          No options found. Please contact us for more details.
        </Typography>
      </div>
    );
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading message="Fetching addons" />
      ) : (
        <React.Fragment>
          <Typography variant="h4" component="div" className={classes.title}>
            Choose your {brand.parent_element.name} {brand.name} options
          </Typography>

          <Typography className={classes.label}>What would you like to add?</Typography>

          <Select
            isSearchable
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            onChange={handleChange}
            options={groupOptions}
            placeholder="Click or start typing to view all options"
            styles={colourStyles}
            value={selectedAddons}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    agreementTerms: state.brand.agreementTerms,
    brand: state.brand.brand,
    groupOptions: state.brand.groupOptions,
    initialRental: state.brand.initialRental,
    isError: state.brand.isErrorAddons,
    isLoading: state.brand.isLoadingAddons,
    leaseType: state.brand.leaseType,
    selectedAddons: state.brand.selectedAddons,
    selectedVariant: state.brand.selectedVariant,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getAddons, handleUpdateAddons }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);

import React from 'react';
import { connect } from 'react-redux';
import {
  IconButton,
  Theme,
  Container,
  Grid,
  Hidden,
  Paper,
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import currency from 'currency.js';
import isEmpty from 'lodash/isEmpty';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { styleConfig } from '../../../../config/config';
import { WordpressWpBrand, WordpressWpCar } from '../../../defs/generated-models';
import { smoothScrollToElement } from '../../../utils/utils';
import MuiButton from '../../MuiButton';

const SelectedVariant: React.FunctionComponent<SelectedVariantProps> = (props) => {
  const {
    addonsTotal,
    brand,
    depositPrice,
    isFixed,
    leaseType,
    monthlyPrice,
    selectedAddons,
    selectedVariant,
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
      margin: '15px 0 0',
    },
    fixedRoot: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      margin: 0,
      left: 0,
      zIndex: 900,
      transition: '250ms ease-in-out all',
      borderTop: '1px #ddd solid',

      [theme.breakpoints.down('sm')]: {
        // height: 142,
      },
    },
    fixedContainer: {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },
    paper: {
      padding: '10px 60px',

      [theme.breakpoints.down('md')]: {
        padding: 15,
      },

      [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 40px 15px',
      },
    },
    selectedVariantWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    imageRoot: {
      width: 130,
      margin: '0 25px 0 0',
    },
    selectedVariantRoot: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 100,

      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
    },
    preTitle: {
      letterSpacing: '1px',
      color: styleConfig.color.text.darkgrey,
      fontSize: 12,
      textTransform: 'uppercase',
      fontWeight: 800,
      opacity: 0.4,
      marginBottom: 5,

      [theme.breakpoints.down('sm')]: {
        marginRight: 15,
        marginBottom: 0,
        fontSize: 11,
      },
    },
    leaseTypeMobile: {
      lineHeight: 0,
      color: styleConfig.color.text.gold,
    },
    selectedVariantModel: {
      fontSize: 20,
      fontFamily: styleConfig.fonts.header,
      fontWeight: 600,
      marginBottom: 5,
      lineHeight: 1,
      color: styleConfig.color.text.darkgrey,

      [theme.breakpoints.down('sm')]: {
        fontSize: 17,
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    selectedVariantTitle: {
      color: styleConfig.color.text.gold,
      fontWeight: 400,
      fontSize: 14,

      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    leaseTypeRoot: {
      textAlign: 'right',
    },
    leaseTypeRootMobile: {
      display: 'flex',
      textAlign: 'left',
      width: '100%',
      padding: '15px 15px 0 8px',
      alignItems: 'center',
    },
    leaseType: {
      fontSize: 14,
      color: styleConfig.color.text.gold,
      fontWeight: 400,
      height: '100%',

      [theme.breakpoints.down('sm')]: {
        fontSize: 17,
      },
    },
    backToTop: {
      border: '1px solid',
      borderColor: styleConfig.color.text.darkgrey,
      padding: 5,
      opacity: 0.4,
      transition: 'all .3s ease-in-out',

      '&:hover': {
        opacity: 1,
        backgroundColor: styleConfig.color.bg.darkgrey,

        '& $backToTopIcon': {
          color: styleConfig.color.text.white,
        },
      },

      [theme.breakpoints.down('md')]: {
        marginRight: 15,
      },
    },
    backToTopSolid: {
      position: 'absolute',
      top: -25,
      left: 'calc(50% - 22px)',
      backgroundColor: `${styleConfig.color.bg.darkergrey}!important`,
      opacity: 1,
      marginRight: 0,

      '& $backToTopIcon': {
        color: styleConfig.color.text.white,
      },
    },
    backToTopIcon: {
      fontSize: 32,
    },
    variantPriceRoot: {
      color: styleConfig.color.text.gold,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',

      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flexWrap: 'wrap',
      },
    },
    variantMonthlyPriceRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: 30,

      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    variantPrice: {
      color: styleConfig.color.text.gold,
      fontSize: 54,
      fontFamily: styleConfig.fonts.header,
      fontWeight: 800,
      lineHeight: 1,
      marginRight: 5,

      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        fontSize: 40,
      },

      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        fontSize: 36,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 30,
      },
    },
    variantPriceRootInner: {
      color: '#2b2b2b',
      display: 'flex',
      flexDirection: 'column',
      minWidth: 75,
      marginLeft: 5,
    },
    poaPrice: {
      fontWeight: 800,
      fontSize: 54,
      color: styleConfig.color.text.gold,
      margin: 0,
      lineHeight: 1,
    },
    variantPricePerMonth: {
      fontSize: 14,
      color: styleConfig.color.text.gold,

      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    variantDetailsRoot: {
      height: '100%',

      [theme.breakpoints.down('sm')]: {
        padding: 0,
        borderLeft: 'none',
      },
    },
    variantSubPrice: {
      fontSize: 13,
      lineHeight: 1.3,
      color: styleConfig.color.text.lightgrey,

      [theme.breakpoints.down('lg')]: {
        fontSize: 11,
        fontWeight: 800,
      },
    },
    sectionRightRoot: {
      height: '100%',

      [theme.breakpoints.down('sm')]: {
        display: 'block',
        paddingLeft: 15,
      },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
      },
    },
    quoteBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    desktopBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    mobileBtn: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
    },
  }));

  const classes = useStyles();

  // Determine if an item is POA within the the selected addons
  const isPOA = !isEmpty(selectedAddons)
    ? !isEmpty(selectedAddons.filter((addon) => addon.isPOA))
    : false;

  return (
    <Box className={clsx(classes.wrapper, isFixed && classes.fixedRoot)}>
      <Container
        maxWidth={isFixed ? false : 'xl'}
        className={isFixed ? classes.fixedContainer : undefined}
      >
        <Paper className={classes.paper}>
          <Grid container justifyContent="center" alignItems="center" spacing={1}>
            <Grid item xl={6} lg={5} md={4} sm={6} xs={6}>
              <Box className={classes.selectedVariantWrapper}>
                <Hidden mdDown>
                  <IconButton
                    className={classes.backToTop}
                    onClick={() => smoothScrollToElement('___gatsby')}
                  >
                    <KeyboardArrowUpIcon className={classes.backToTopIcon} />
                  </IconButton>
                </Hidden>

                <Hidden mdUp>
                  <IconButton
                    className={clsx([classes.backToTop, classes.backToTopSolid])}
                    onClick={() => smoothScrollToElement('___gatsby')}
                  >
                    <KeyboardArrowUpIcon className={classes.backToTopIcon} />
                  </IconButton>
                </Hidden>

                <Box className={classes.selectedVariantRoot}>
                  <Typography component="span" className={classes.preTitle}>
                    Selected
                  </Typography>

                  <Typography component="span" className={classes.selectedVariantModel}>
                    {brand.parent_element && brand.parent_element.name} {brand.name}
                  </Typography>
                  <Typography component="span" className={classes.selectedVariantTitle}>
                    {selectedVariant.post_meta_fields && selectedVariant.post_meta_fields.title}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xl={4} lg={5} md={4} sm={6} xs={6}>
              <div className={classes.sectionRightRoot}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  className={classes.variantDetailsRoot}
                  spacing={2}
                >
                  <Hidden smDown>
                    <Grid item lg={5} md={4} sm={4} xs={12} className={classes.leaseTypeRoot}>
                      <Typography className={classes.preTitle}>Selected lease type:</Typography>

                      <Typography className={classes.leaseType}>
                        {leaseType === 'business' ? 'Business Lease' : 'Personal Lease'}
                      </Typography>
                    </Grid>
                  </Hidden>

                  <Hidden mdUp>
                    <div className={classes.leaseTypeRootMobile}>
                      <Typography className={classes.preTitle}>Lease Type:</Typography>
                      <Typography className={classes.leaseTypeMobile}>
                        {leaseType === 'business' ? 'Business' : 'Personal'}
                      </Typography>
                    </div>
                  </Hidden>

                  <Grid item lg={7} md={7} sm={12} xs={12}>
                    <div className={classes.variantPriceRoot}>
                      <div>
                        {monthlyPrice === 0 && (
                          <div>
                            <Typography className={classes.poaPrice}>£POA</Typography>
                          </div>
                        )}
                        {monthlyPrice !== 0 && (
                          <Typography className={classes.variantPrice}>
                            {currency(monthlyPrice, { precision: 0, symbol: '£' }).format()}
                          </Typography>
                        )}
                      </div>

                      {monthlyPrice !== 0 && (
                        <div className={classes.variantPriceRoot}>
                          <span className={classes.variantPriceRootInner}>
                            <Typography className={classes.variantPricePerMonth}>
                              per month
                            </Typography>
                          </span>
                        </div>
                      )}
                    </div>

                    {addonsTotal > 0 &&
                      (isPOA ? (
                        <Typography className={classes.variantSubPrice}>
                          Additional items £POA
                        </Typography>
                      ) : (
                        <Typography className={classes.variantSubPrice}>
                          Inc. additional items of £
                          {currency(addonsTotal, { precision: 0, symbol: '£' }).format()}
                        </Typography>
                      ))}

                    {monthlyPrice !== 0 && (
                      <Typography className={classes.variantSubPrice}>
                        Initial rental of{' '}
                        {currency(depositPrice, { precision: 0, symbol: '£' }).format()}{' '}
                        {leaseType === 'personal' ? '(Inc. Vat)' : '(Excl. VAT)'}
                      </Typography>
                    )}

                    {addonsTotal === 0 && (
                      <Hidden smDown>
                        <Typography className={classes.variantSubPrice}>
                          No additional items
                        </Typography>
                      </Hidden>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {/* Mobile Button */}
            <Hidden mdUp>
              <div className={classes.mobileBtn}>
                <MuiButton
                  fullWidth
                  aria-label="Enquire now"
                  height={30}
                  onClick={() => smoothScrollToElement('customiseLease')}
                  name="enquire-now"
                  variant="contained"
                  margin="0px"
                >
                  Enquire now
                </MuiButton>
              </div>
            </Hidden>

            {/* Desktop Button */}
            <Hidden smDown>
              <Grid item xl={2} lg={2} md={4}>
                <div className={classes.desktopBtn}>
                  <MuiButton
                    aria-label="Enquire now"
                    size="medium"
                    height={61}
                    onClick={() => smoothScrollToElement('customiseLease')}
                    name="enquire-now"
                    variant="contained"
                  >
                    Enquire now
                  </MuiButton>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    addonsTotal: state.brand.addonsTotal,
    brand: state.brand.brand,
    depositPrice: state.brand.depositPrice,
    isLoading: state.brand.isLoadingVariant,
    leaseType: state.brand.leaseType,
    monthlyPrice: state.brand.monthlyPrice,
    selectedAddons: state.brand.selectedAddons,
    selectedVariant: state.brand.selectedVariant,
  };
};

interface SelectedVariantProps {
  addonsTotal: number;
  selectedAddons: {
    isPOA: boolean;
    label: string;
    parent: string;
    priceExcVat: number;
    priceIncVat: number;
    value: string;
  }[];
  isFixed: boolean;
  brand: WordpressWpBrand;
  depositPrice: number;
  isLoading: boolean;
  leaseType: string;
  monthlyPrice: number;
  selectedVariant: WordpressWpCar;
}

export default connect(mapStateToProps)(SelectedVariant);

import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.white,
    fontSize: 45,
    fontWeight: 600,
    marginBottom: 10,
  },
}));

const Title: React.FunctionComponent<TitleProps> = (props) => {
  const { title } = props;

  const classes = useStyles();

  return (
    <Typography variant="h1" className={classes.title}>
      {title}
    </Typography>
  );
};

interface TitleProps {
  title: string;
}

export default Title;

import React, { useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Config
import { styleConfig } from '../../../config/config';

const PipeButton: React.SFC<PipeButtonProps> = (props) => {
  const { animated } = props;

  const [isAnimating, setIsAnimating] = useState(true);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    if (animated) {
      setTimeout(() => {
        setIsAnimating(false);
        setHasAnimated(true);
      }, 1000);
    } else {
      setHasAnimated(true);
    }
  }, [animated]);

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: 'block',
      position: 'absolute',
      top: '6rem',
      zIndex: 500,
      left: '50%',
      transform: 'translate3d(-50%, 0, 0)',
      willChange: 'transform',
      transition: !animated ? 'height .3s ease-in-out' : 'none',
      height: !isAnimating || !animated ? '10rem' : '0',
      width: '1px',
      backgroundColor: styleConfig.color.bg.gold,

      '&:before': {
        content: `''`,
        position: 'absolute',
        left: '-5px',
        bottom: '-10px',
        borderRadius: '100%',

        width: '11px',
        height: '11px',
        backgroundColor: styleConfig.color.bg.gold,

        transition: !animated ? 'all .3s ease-in-out' : 'none',
        transitionDelay: animated ? '.25s' : '0s',
        transform: hasAnimated ? 'scale(1)' : 'scale(0)',
        opacity: hasAnimated ? 1 : 0,
      },

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  }));

  const classes = useStyles();

  return <div className={classes.root}></div>;
};

interface PipeButtonProps {
  animated?: boolean;
}

export default PipeButton;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const TextButton: React.FC<TextButtonProps> = (props) => {
  const { color, onClick, disabled, children } = props;

  const useStyles = makeStyles(() => ({
    root: {
      color: color && color,
      border: 'none',
      backgroundColor: 'transparent',
      textTransform: 'uppercase',
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: 13,
      alignItems: 'center',
      letterSpacing: 1,
      cursor: 'pointer',
      padding: 0,
      display: 'inline-flex',
      outline: 0,

      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
        '&:hover': {
          textDecoration: 'none',
        },
      },

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }));

  const classes = useStyles();

  return (
    <button type="button" onClick={() => onClick()} className={classes.root} disabled={disabled}>
      {children}
    </button>
  );
};

interface TextButtonProps {
  disabled?: boolean;
  onClick: Function;
  color: string;
}

export default TextButton;
